export enum SupportedCurrency {
  EUR = 'EUR',
  CHF = 'CHF',
  GBP = 'GBP',
}

export enum AmountType {
  UNIT = 'UNIT',
  CENT = 'CENT',
}

export type Money = {
  amountInCents: number;
  currency: SupportedCurrency;
  formattedString: string;
};
