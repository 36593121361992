import { useDeepCompareMemo } from '@react-hookz/web';

import { GEDECK_ITEM } from 'constants/lucaPreorder';
import {
  DenormalizedMenuCategoryType,
  DenormalizedMenuItemType,
  DenormalizedMenuType,
  FlatMenuCategoryType,
  FlatMenuItemType,
  FlatMenuType,
} from 'types';

export const getDenormalizedMenuCategoryById = (
  categories: DenormalizedMenuCategoryType[],
  categoryId: string
) => {
  return categories.find(category => category.uuid === categoryId);
};

export const getDenormalizedMenuItemById = (
  items: DenormalizedMenuItemType[],
  itemId: string
) => {
  return items.find(item => item.uuid === itemId);
};

export const hasEnabledItems = ({
  categories,
  categoryId,
}: {
  categories: DenormalizedMenuCategoryType[];
  categoryId: string;
}) => {
  const category = getDenormalizedMenuCategoryById(categories, categoryId);

  if (!category?.isEnabled) {
    return false;
  }

  if (category?.subcategories && category?.subcategories.length > 0) {
    const atLeastOneCategoryIsEnabled = category?.subcategories.some(
      subcategory =>
        hasEnabledItems({
          categories,
          categoryId: subcategory.uuid,
        })
    );

    if (atLeastOneCategoryIsEnabled) {
      return true;
    }
  }

  return category.items?.some(item => !!item.isEnabled);
};

const denormalizeFlatMenuItem = (
  flatMenu: FlatMenuType,
  item: FlatMenuItemType
): DenormalizedMenuItemType => {
  const selectCategories = item.selectCategoryIds
    ?.map(categoryId => {
      return flatMenu.categories.find(category => category.uuid === categoryId);
    })
    .filter((category): category is FlatMenuCategoryType => !!category);

  return {
    ...item,
    selectCategories,
  };
};
const denormalizeFlatMenuCategory = (
  flatMenu: FlatMenuType,
  category: FlatMenuCategoryType,
  denormalizedItems: DenormalizedMenuItemType[]
): DenormalizedMenuCategoryType => {
  const {
    items: itemsIds,
    subcategories: subcategoriesIds,
    ...restCategoryProperties
  } = category;

  const subcategories = subcategoriesIds
    ?.map(subCategoryId => {
      return flatMenu.categories.find(categ => categ.uuid === subCategoryId);
    })
    .filter(
      (subcategory): subcategory is FlatMenuCategoryType => !!subcategory
    );

  const items = itemsIds
    ?.map(itemId => {
      return denormalizedItems.find(
        normalizedCategory => normalizedCategory.uuid === itemId
      );
    })
    .filter((item): item is DenormalizedMenuItemType => !!item);

  return {
    ...restCategoryProperties,
    subcategories,
    items,
  };
};

export const denormalizeFlatMenu = (
  flatMenu: FlatMenuType
): DenormalizedMenuType => {
  let denormalizedItems = flatMenu.items.map(item =>
    denormalizeFlatMenuItem(flatMenu, item)
  );
  // Special case: Munich Opera
  // The opera has a Gedeck item which is automatically added for specific menu items
  // The category containing the Gedeck item is not supposed to be shown in the menu itself, and therefore removed from rootCategories
  // The operator app itself shows the Gedeck item, as it is manually added by the waiter
  const gedeckItem = denormalizedItems.find(
    itemToFind => itemToFind.pid === GEDECK_ITEM.pid
  );
  if (gedeckItem) {
    denormalizedItems = denormalizedItems.map(item => {
      if (item.uuid === gedeckItem.uuid) {
        return {
          ...item,
          isHidden: true,
        };
      }
      return item;
    });
  }

  const denormalizedCategories = flatMenu.categories.map(category =>
    denormalizeFlatMenuCategory(flatMenu, category, denormalizedItems)
  );

  const denormalizedRootCategories: DenormalizedMenuCategoryType[] =
    flatMenu.rootCategories
      .map(rootCategoryId => {
        return denormalizedCategories.find(
          category => category.uuid === rootCategoryId
        );
      })
      .filter(
        (category): category is DenormalizedMenuCategoryType => !!category
      );

  const validRootCategories = denormalizedRootCategories.filter(category =>
    hasEnabledItems({
      categoryId: category.uuid,
      categories: denormalizedCategories,
    })
  );

  return {
    rootCategories: validRootCategories,
    categories: denormalizedCategories,
    items: denormalizedItems,
    barcodes: flatMenu.barcodes,
    allergensAndAdditives: flatMenu.allergensAndAdditives,
    imprint: flatMenu.imprint,
    isPOSImported: flatMenu.isPOSImported,
    gedeckItem: gedeckItem ?? null,
  };
};

export const getSubCategoriesForCategoryId = ({
  menu,
  categoryId,
}: {
  menu: FlatMenuType | undefined;
  categoryId: string;
}) => {
  const subcategories: FlatMenuCategoryType[] = [];
  const parentCategory = menu?.categories.find(
    menuCategory => menuCategory.uuid === categoryId
  );
  if (
    !parentCategory?.subcategories ||
    parentCategory.subcategories.length === 0
  ) {
    return subcategories;
  }

  parentCategory.subcategories.forEach(subcategoryId => {
    const subCategory = menu?.categories.find(
      menuCategory => menuCategory.uuid === subcategoryId
    );
    if (subCategory) {
      subcategories.push(subCategory);
    }
  });
  return subcategories;
};

export const useEnrichedMenuItems = (
  itemIds: string[],
  menu: DenormalizedMenuType
): DenormalizedMenuItemType[] => {
  return useDeepCompareMemo(() => {
    if (!itemIds.length) {
      return [];
    }

    return itemIds
      .map(itemId => getDenormalizedMenuItemById(menu.items, itemId))
      .filter((item): item is DenormalizedMenuItemType => !!item);
  }, [menu.items, itemIds]);
};
