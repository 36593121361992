/* eslint-disable max-lines */
import saveAs from 'file-saver';
import {
  LocationGroupSettingsType,
  ClubInviteType,
  StayBookingType,
  ClubReferralApplicationType,
  ConsumerDataType,
  GetCheckoutResponse,
  PreorderLocationsResponseType,
  getBundleResponseSchema,
  PossibleGuestCountsResponseType,
  createClubApplicationBodySchema,
  getClubReferralResponseSchema,
  PaymentMethodV2ResponseType,
  CreateCheckoutResponse,
  CreateBundleReservationCheckoutRequest,
  CreateReservationCheckoutResponse,
  CreateCheckoutRequestV3,
  SignupConsumerType,
  PaymentSettingsResponseV3Type,
  GetVoucherResponse,
  LocationPaymentRequestParameterType,
  OrderPaymentParameterType,
  ConsumerInitiatedPaymentParameterType,
  ConsumerPaymentMethodV3Type,
  SerializedOrderItemType,
  CreatePaymentRequestCheckoutRequestV3,
  CreateBundleCheckoutRequestType,
  CreateOrderCheckoutV3Type,
  GetOpenTablesForSlotsResponseType,
  getPreorderShowOccurrenceResponseSchema,
  GetCheckoutFolioResponseType,
  CreateCheckoutV3PaymentInfoType,
  PublicLocationResponseType,
  ConsumerDetailsType,
  CreateMeldescheinRequestType,
  DenormalizedMenuType,
  CreateMeldescheinResponseType,
  GetActiveConsumerReservationsResponseType,
  StayLocationGroupSettingsType,
  PublicLocationSettingsResponseType,
  LinkedReservation,
  ReceiptPaymentType,
  HotelGuestWishResponseType,
  HotelGuestWishRequestType,
  CreateOrderV3ResponseType,
  GetOrdersV3ResponseType,
  GetOrdersV3OrderType,
  PaymentMethodCodeEnum,
  ConsumerHotelGuestWishResponseType,
} from 'types';
import {
  convertToSearchParametersString,
  denormalizeFlatMenu,
  getAccessTokenForRequest,
  SupportedLanguageEnum,
} from 'utils';

import { ApiError, headers } from './constants';
import { validateAndParse } from './utils';
import { getJwtForLinkDevice } from './webApi';

export const API_PATH = '/pay/api';

export const getLocationGroupConfig = async (
  discoverId: string,
  consumerData: ConsumerDataType | null
) => {
  const accessToken = await getAccessTokenForRequest(consumerData);

  const response = await fetch(
    `${API_PATH}/v3/public/discover/${discoverId}/lucaPoints/config`,
    {
      headers: { ...headers, ...(accessToken && { 'X-Auth': accessToken }) },
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to get locationGroup ${discoverId} config, status code ${response.status}`
    );
  }

  return response.json();
};

export const getPointsForUser = async (
  consumerData: ConsumerDataType | null
) => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v1/consumers/points/balance`, {
    headers: { ...headers, 'X-Auth': accessToken },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to get consumer points, failed with status code ${response.status}`
    );
  }

  return response.json();
};

export const getAuthenticationCode = async (
  username: string,
  password: string
) => {
  if (!username || !password) {
    throw new Error(
      'Failed to get consumer authcode due to missing username or password.'
    );
  }
  const response = await fetch(`${API_PATH}/v1/consumers/signin`, {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers,
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getOpenLocationTablePaymentRequests = async (
  locationId?: string,
  tableId?: string | null
) => {
  const tableParameter = tableId
    ? `?${convertToSearchParametersString({
        table: tableId,
      })}`
    : '';
  const response = await fetch(
    `${API_PATH}/v1/locations/${locationId}/paymentRequests/open${tableParameter}`,
    {
      headers,
    }
  );

  if (!response.ok) {
    if (response.status === 404) {
      return null;
    }

    throw new Error(
      `Failed to get payment active state, status code ${response.status}`
    );
  }
  return response.json();
};

export const getLocationGroupSettingsByLocationId = async (
  locationId?: string
): Promise<LocationGroupSettingsType | null> => {
  const response = await fetch(
    `${API_PATH}/v1/locations/${locationId}/paymentSettings`,
    {
      headers,
    }
  );

  if (!response.ok) {
    if (response.status === 404) {
      return null;
    }

    throw new Error(
      `Failed to get payment active state, status code ${response.status}`
    );
  }

  if (!response.ok) {
    throw new Error(
      `Failed to get location payment settings, status code ${response.status}`
    );
  }
  return response.json();
};

export const createOrder = async ({
  consumerAccessToken,
  consumerData,
  locationId,
  items,
  table,
  note,
}: {
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
  locationId?: string | null;
  items: SerializedOrderItemType[];
  table?: string | null;
  note?: string | null;
}): Promise<CreateOrderV3ResponseType | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v3/consumers/orders`, {
    method: 'POST',
    body: JSON.stringify({
      locationId,
      items,
      ...(table !== null ? { table } : {}),
      ...(note !== null ? { note } : {}),
    }),
    headers: { ...headers, 'X-Auth': consumerToken },
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const createPreCheckin = async ({
  checkinData,
  consumerData,
  consumerAccessToken,
}: {
  checkinData: CreateMeldescheinRequestType;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
}): Promise<CreateMeldescheinResponseType | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }
  const { pmsReference, discoverId, ...meldescheinPayload } = checkinData;

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/meldescheins`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...meldescheinPayload,
      }),
      headers: {
        ...headers,
        'X-Auth': consumerToken,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const getPublicLocation = (
  locationId: string
): Promise<PublicLocationResponseType> => {
  return fetch(`${API_PATH}/v3/public/locations/${locationId}`, {
    method: 'GET',
    headers,
  })
    .then(validateAndParse)
    .catch(error => {
      throw new Error(
        `Error requesting public location for locationId ${locationId} - ${error.status}`
      );
    });
};

export const getLocationMenuV3Flat = async (
  locationId: string,
  date?: string
): Promise<DenormalizedMenuType> => {
  const searchParameters = date
    ? `?${convertToSearchParametersString({
        date,
      })}`
    : '';

  const response = await fetch(
    `${API_PATH}/v3/public/locations/${locationId}/menu/flat${searchParameters}`,
    { headers }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return denormalizeFlatMenu(await response.json());
};

export const getLocationSettings = async (
  locationId: string
): Promise<PublicLocationSettingsResponseType> => {
  const response = await fetch(
    `${API_PATH}/v3/public/locations/${locationId}/settings`
  );
  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const getClubInvitation = async (
  inviteId: string | undefined
): Promise<ClubInviteType | null> => {
  const response = await fetch(`${API_PATH}/v3/public/club/invite/${inviteId}`);
  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const getBooking = async ({
  pmsReference,
  discoverId,
  consumerAccessToken,
  consumerData,
}: {
  pmsReference?: string | null;
  discoverId?: string;
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
}): Promise<StayBookingType | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}`,
    {
      headers: { ...headers, 'X-Auth': consumerToken },
    }
  );
  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const uploadImage = async ({
  pmsReference,
  formData,
  discoverId,
  consumerData,
  consumerAccessToken,
}: {
  pmsReference?: string | null;
  formData: FormData;
  discoverId: string;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
}): Promise<{ uploadId: string } | any> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/images`,
    {
      method: 'POST',
      headers: { 'X-Auth': consumerToken },
      body: formData,
    }
  );

  if (!response.ok) {
    console.error('Could not upload image: Network response was not ok');
    return response;
  }

  return response.json();
};

export const getImageUrl = async ({
  pmsReference,
  discoverId,
  consumerData,
  consumerAccessToken,
  uploadId,
}: {
  pmsReference?: string | null;
  discoverId: string;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
  uploadId: string | null;
}): Promise<{ buffer: Buffer } | any> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken || !uploadId) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/images/${uploadId}`,
    {
      headers: { ...headers, 'X-Auth': consumerToken },
    }
  );

  if (!response.ok) {
    console.error('Could not upload image: Network response was not ok');
    return response;
  }

  return response.json();
};

export const signUpClubInterest = async ({
  inviteId,
  email,
}: {
  inviteId: string | undefined;
  email: string;
}) => {
  const response = await fetch(`${API_PATH}/v3/public/club/interest`, {
    method: 'POST',
    body: JSON.stringify({
      inviteId,
      email,
    }),
    headers,
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getMeWithAccessToken = async (
  accessToken: string
): Promise<ConsumerDetailsType> => {
  const response = await fetch(`${API_PATH}/v1/consumers/me`, {
    headers: {
      ...headers,
      'X-Auth': accessToken,
    },
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getMe = async ({
  consumerData,
}: {
  consumerData: ConsumerDataType | null;
}): Promise<{ email: string | null; marketingOptIn: boolean }> => {
  const accessToken = await getAccessTokenForRequest(consumerData);

  if (!accessToken) {
    return { email: null, marketingOptIn: false };
  }

  const response = await fetch(`${API_PATH}/v1/consumers/me`, {
    headers: { ...headers, 'X-Auth': accessToken },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to get consumer, failed with status code ${response.status}`
    );
  }

  return response.json();
};

export const getClubMemberReferral = async (
  clubReferralId: string | undefined
): Promise<getClubReferralResponseSchema> => {
  const response = await fetch(
    `${API_PATH}/v3/public/clubs/referral/${clubReferralId}`,
    {
      headers,
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const createClubMemberApplication = async (
  clubReferralId: string,
  applicationData: createClubApplicationBodySchema
) => {
  const response = await fetch(
    `${API_PATH}/v3/public/clubs/referral/${clubReferralId}/apply`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(applicationData),
    }
  );
  if (!response.ok) {
    throw new ApiError(response);
  }
  return response;
};

export const createClubMemberReferral = async ({
  accessToken,
  referralData,
}: {
  accessToken: string;
  referralData: Partial<ClubReferralApplicationType>;
}) => {
  const response = await fetch(`${API_PATH}/v3/consumers/clubs/referral`, {
    headers: { ...headers, 'X-Auth': accessToken },
    method: 'POST',
    body: JSON.stringify(referralData),
  });

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const getPreorderShows = async (discoverId?: string) => {
  const response = await fetch(
    `${API_PATH}/v3/public/shows/discover/${discoverId}/showOccurrences`,
    { method: 'GET', headers }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const getPreorderShowOccurrences = async (
  showOccurrenceId: string | undefined
): Promise<getPreorderShowOccurrenceResponseSchema> => {
  const response = await fetch(
    `${API_PATH}/v3/public/shows/showOccurrences/${showOccurrenceId}`,
    { method: 'GET', headers }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const createBundle = async ({
  showOccurrenceId,
}: {
  showOccurrenceId: string | undefined;
}) => {
  const response = await fetch(`${API_PATH}/v3/public/showBundles`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ showOccurrenceId }),
  });

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const updateBundleOrders = async ({
  bundleId,
  order,
}: {
  bundleId: string;
  order: {
    locationId?: string | null;
    items: SerializedOrderItemType[];
    note?: string | null;
    showOccurrenceSlotId?: string | null;
  };
}) => {
  const response = await fetch(
    `${API_PATH}/v3/public/showBundles/${bundleId}/orders`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({ ...order }),
    }
  );

  if (!response.ok) {
    if (response.status === 409) {
      return { error: 409 };
    }
    throw new ApiError(response);
  }
  return response.json();
};

export const updateBundleReservations = async ({
  bundleId,
  reservation,
}: {
  bundleId: string;
  reservation: {
    showOccurrenceSlotId: string;
    locationId: string | undefined;
    guestCount: number;
    message?: string;
  };
}) => {
  const response = await fetch(
    `${API_PATH}/v3/public/showBundles/${bundleId}/reservations`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({ ...reservation }),
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const deleteOrderFromBundle = async ({
  bundleId,
  orderId,
}: {
  bundleId: string;
  orderId: string;
}) => {
  const response = await fetch(
    `${API_PATH}/v3/public/showBundles/${bundleId}/orders/${orderId}`,
    {
      method: 'DELETE',
      headers,
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response;
};

export const deleteReservationFromBundle = async ({
  bundleId,
  reservationId,
}: {
  bundleId: string;
  reservationId: string;
}) => {
  const response = await fetch(
    `${API_PATH}/v3/public/showBundles/${bundleId}/reservations/${reservationId}`,
    {
      method: 'DELETE',
      headers,
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response;
};

export const getBundle = async ({
  consumerAccessToken,
  consumerData,
}: {
  consumerAccessToken?: string | null;
  consumerData?: ConsumerDataType | null;
}): Promise<getBundleResponseSchema | null> => {
  if (!consumerAccessToken) {
    // eslint-disable-next-line no-throw-literal
    throw {
      error: 'NO_CONSUMER_ACCESS_TOKEN',
    };
  }
  let authToken = null;

  if (consumerData) {
    authToken = await getAccessTokenForRequest(consumerData);
  }

  const headersForRequest = authToken
    ? { ...headers, 'X-Auth': authToken }
    : { ...headers };

  const response = await fetch(
    `${API_PATH}/v3/consumers/showBundles?accessToken=${consumerAccessToken}`,
    {
      method: 'GET',
      headers: headersForRequest,
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const getCheckout = async (
  checkoutId: string
): Promise<GetCheckoutResponse> => {
  const response = await fetch(
    `${API_PATH}/v3/public/checkouts/${checkoutId}`,
    {
      headers,
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to get checkout, status code ${response.status}`);
  }

  return response.json();
};

export const getPreorderLocations = async (
  discoverId: string | undefined
): Promise<PreorderLocationsResponseType> => {
  const response = await fetch(
    `${API_PATH}/v3/public/locationGroups/discover/${discoverId}/locationAttributes?types=SERVICE_TYPE,LOCATION,GROUPED_NAME`,
    {
      method: 'GET',
      headers,
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const getPossibleGuestCounts = async (
  locationId: string | null | undefined,
  showOccurrenceSlotId: string | undefined
): Promise<PossibleGuestCountsResponseType> => {
  const response = await fetch(
    `${API_PATH}/v3/public/shows/showOccurrenceSlots/${showOccurrenceSlotId}/reservations/possibleGuestCounts?locationId=${locationId}`,
    {
      method: 'GET',
      headers,
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response.json();
};

export const getCampaignsForLocation = async (
  locationId: string | undefined,
  consumer: ConsumerDataType | null
) => {
  let token;
  try {
    if (consumer) {
      const { username, password } = consumer;
      const { accessToken } = await getAuthenticationCode(username, password);
      token = accessToken;
    }
  } catch (error) {
    console.error(`Error retrieving token for consumer with ${error}`);
  }
  return fetch(`${API_PATH}/v1/locations/${locationId}/campaigns`, {
    method: 'GET',
    headers: {
      ...headers,
      ...(token ? { 'X-Auth': token } : {}),
    },
  })
    .then(validateAndParse)
    .catch(error => {
      throw new Error(
        `Error requesting campaings with status - ${error.status}`
      );
    });
};

export const getMeProfile = async ({
  consumerData,
}: {
  consumerData: ConsumerDataType | null;
}): Promise<{
  fullName: string | null;
  callName: string | null;
  email: string | null;
  phoneNumber: string | null;
}> => {
  const accessToken = await getAccessTokenForRequest(consumerData);

  if (!accessToken) {
    return {
      fullName: null,
      callName: null,
      email: null,
      phoneNumber: null,
    };
  }

  const response = await fetch(`${API_PATH}/v1/consumers/me/profile`, {
    headers: { ...headers, 'X-Auth': accessToken },
  });
  if (!response.ok) {
    throw new Error(
      `Failed to get consumer profile, failed with status code ${response.status}`
    );
  }
  return response.json();
};

export const setMe = async ({
  consumerData,
  email,
  marketingOptIn,
}: {
  consumerData: ConsumerDataType | null;
  email: string;
  marketingOptIn: boolean;
}): Promise<number | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;
  const response = await fetch(`${API_PATH}/v1/consumers/me`, {
    method: 'PATCH',
    headers: { ...headers, 'X-Auth': accessToken },
    body: JSON.stringify({ email, marketingOptIn }),
  });

  return response.status;
};

export const setMeProfile = async ({
  consumerData,
  fullName,
  phoneNumber,
}: {
  consumerData: ConsumerDataType | null;
  fullName: string;
  phoneNumber: string;
}): Promise<number | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;

  const response = await fetch(`${API_PATH}/v1/consumers/me/profile`, {
    method: 'PATCH',
    headers: { ...headers, 'X-Auth': accessToken },
    body: JSON.stringify({ fullName, phoneNumber }),
  });

  return response.status;
};

export const createApplePaySession = async ({
  consumerData,
  paymentMethodCode,
}: {
  consumerData: ConsumerDataType | null;
  paymentMethodCode:
    | PaymentMethodCodeEnum.ADYEN_APPLE
    | PaymentMethodCodeEnum.RAPYD_APPLE;
}): Promise<{ data: string } | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/public/checkouts/session/applePay`,
    {
      method: 'POST',
      headers: { ...headers, 'X-Auth': accessToken },
      body: JSON.stringify({ paymentMethodCode }),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to get apple token, status code ${response.status}`
    );
  }

  return response.json();
};

export const getOrderById = async ({
  consumerData,
  orderId,
}: {
  consumerData: ConsumerDataType | null;
  orderId?: string | null;
}): Promise<GetOrdersV3OrderType | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v3/consumers/orders/${orderId}`, {
    headers: { ...headers, 'X-Auth': accessToken },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to get order for orderId: ${orderId}, status code ${response.status}`
    );
  }
  return response.json();
};

export const deleteOrderById = async ({
  consumerAccessToken,
  consumerData,
  orderId,
}: {
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
  orderId?: string | null;
}) => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v3/consumers/orders/${orderId}`, {
    method: 'DELETE',
    headers: { ...headers, 'X-Auth': consumerToken },
  });
  if (!response.ok) {
    throw new Error(
      `Failed to delete order for orderId: ${orderId}, status code ${response.status}`
    );
  }
  return response;
};

export const createBundleCheckout = async ({
  bundleId,
  consumerAccessToken,
  consumerData,
  requestPayload,
}: {
  bundleId: string;
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
  requestPayload: CreateBundleCheckoutRequestType;
}): Promise<CreateCheckoutResponse | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);

  if (!accessToken) {
    return null;
  }

  const headerForRequest =
    consumerAccessToken || !accessToken
      ? { ...headers }
      : { ...headers, 'X-Auth': accessToken };

  const response = await fetch(
    `${API_PATH}/v3/consumers/showBundles/${bundleId}/checkout${
      consumerAccessToken ? `?accessToken=${consumerAccessToken}` : ''
    }`,
    {
      method: 'POST',
      headers: headerForRequest,
      body: JSON.stringify(requestPayload),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to create bundle checkout, status code ${response.status}`
    );
  }
  return (await response.json()) as CreateCheckoutResponse;
};

export const createBundleReservationCheckout = async ({
  consumerData,
  request,
}: {
  consumerData: ConsumerDataType | null;
  request: CreateBundleReservationCheckoutRequest;
}): Promise<CreateReservationCheckoutResponse | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v2/bundles/${request.bundleId}/commit`,
    {
      method: 'POST',
      headers: { ...headers, 'X-Auth': accessToken },
      body: JSON.stringify({ email: request.email }),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to create bundle checkout, status code ${response.status}`
    );
  }
  return (await response.json()) as CreateCheckoutResponse;
};

export const cancelBundle = async ({
  consumerData,
  bundleId,
  consumerAccessToken,
}: {
  consumerData: ConsumerDataType | null;
  bundleId: string | undefined;
  consumerAccessToken: string | null;
}) => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) {
    return null;
  }

  const headerForRequest =
    consumerAccessToken || !accessToken
      ? { ...headers }
      : { ...headers, 'X-Auth': accessToken };
  const response = await fetch(
    `${API_PATH}/v3/consumers/showBundles/${bundleId}/refund?${
      consumerAccessToken ? `accessToken=${consumerAccessToken}` : ''
    }`,
    {
      method: 'POST',
      headers: headerForRequest,
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
  return response;
};

export const getVoucher = async (
  voucherCode?: string | null
): Promise<GetVoucherResponse> => {
  const voucherParameter = voucherCode
    ? `?${convertToSearchParametersString({
        code: voucherCode,
      })}`
    : '';
  const response = await fetch(`${API_PATH}/v2/vouchers${voucherParameter}`, {
    headers,
  });

  if (!response.ok) {
    if (response.status === 404) {
      throw new ApiError(response);
    }
    throw new Error(`Failed to get voucher, status code ${response.status}`);
  }
  return response.json();
};

export const createVoucherRedeem = async ({
  email,
  marketingOptIn,
  code,
}: {
  email: string;
  marketingOptIn: boolean;
  code: string | null;
}): Promise<number | ApiError | null> => {
  const response = await fetch(`${API_PATH}/v2/vouchers/redeem`, {
    method: 'POST',
    body: JSON.stringify({ email, marketingOptIn, code }),
    headers,
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.status;
};

export const getPaymentSettingsV3 = async (
  locationGroupId: string
): Promise<PaymentSettingsResponseV3Type> => {
  const response = await fetch(
    `${API_PATH}/v3/public/locationGroups/${locationGroupId}/paymentSettings`,
    { headers }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to get location group payment settings for locationGroupId ${locationGroupId}, status code ${response.status}`
    );
  }

  return response.json();
};

export const getDiscoverPaymentSettingsV3 = async (
  discoverId: string
): Promise<PaymentSettingsResponseV3Type> => {
  const response = await fetch(
    `${API_PATH}/v3/public/discover/${discoverId}/paymentSettings`,
    { headers }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to get location group payment settings for discoverId ${discoverId}, status code ${response.status}`
    );
  }

  return response.json();
};

export const createConsumer = async (
  username: string,
  password: string,
  name: string
): Promise<SignupConsumerType> => {
  const response = await fetch(`${API_PATH}/v1/consumers/signup`, {
    method: 'POST',
    body: JSON.stringify({ username, password, name }),
    headers,
  });

  if (!response.ok) {
    throw new Error(
      `Failed to create consumer, failed with status code ${response.status}`
    );
  }

  return response.json();
};

export const createCheckout = async ({
  consumerData,
  request,
}: ConsumerInitiatedPaymentParameterType): Promise<CreateCheckoutResponse | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v2/checkouts`, {
    method: 'POST',
    headers: { ...headers, 'X-Auth': accessToken },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to create checkout, status code ${response.status}`
    );
  }
  return (await response.json()) as CreateCheckoutResponse;
};

export const createCheckoutForPaymentRequest = async ({
  consumerData,
  locationId,
  paymentRequestId,
  request,
}: LocationPaymentRequestParameterType): Promise<CreateCheckoutResponse | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;

  const response = await fetch(
    `${API_PATH}/v2/locations/${locationId}/paymentRequests/${paymentRequestId}/checkout`,
    {
      method: 'POST',
      headers: { ...headers, 'X-Auth': accessToken },
      body: JSON.stringify(request),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to create checkout, status code ${response.status}`
    );
  }
  return (await response.json()) as CreateCheckoutResponse;
};

export const createOrderCheckout = async ({
  consumerData,
  request,
}: OrderPaymentParameterType): Promise<CreateCheckoutResponse | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;

  const response = await fetch(`${API_PATH}/v1/orders/checkout`, {
    method: 'POST',
    headers: {
      ...headers,
      'X-Auth': accessToken,
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to create checkout, status code ${response.status}`
    );
  }
  return (await response.json()) as CreateCheckoutResponse;
};

export const createCheckoutV3 = async (
  consumerData: ConsumerDataType | null,
  request: CreateCheckoutRequestV3
): Promise<CreateCheckoutResponse | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;

  const response = await fetch(`${API_PATH}/v3/consumers/checkouts`, {
    method: 'POST',
    headers: {
      ...headers,
      'X-Auth': accessToken,
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to create checkout, status code ${response.status}`
    );
  }
  return (await response.json()) as CreateCheckoutResponse;
};

export const createPaymentRequestCheckoutV3 = async (
  locationPaymentRequestId: string,
  consumerData: ConsumerDataType | null,
  request: CreatePaymentRequestCheckoutRequestV3
): Promise<CreateCheckoutResponse | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);

  if (!accessToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/public/locationPaymentRequests/${locationPaymentRequestId}/checkouts`,
    {
      method: 'POST',
      headers: {
        ...headers,
        'X-Auth': accessToken,
      },
      body: JSON.stringify(request),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to create checkout, status code ${response.status}`
    );
  }
  return (await response.json()) as CreateCheckoutResponse;
};

export const createOrderCheckoutV3 = async ({
  orderId,
  consumerData,
  request,
}: {
  orderId?: string;
  consumerData: ConsumerDataType | null;
  request: CreateOrderCheckoutV3Type;
}): Promise<CreateCheckoutResponse | null> => {
  let accessToken = null;

  if (consumerData) {
    accessToken = await getAccessTokenForRequest(consumerData);
  }

  const response = await fetch(
    `${API_PATH}/v3/public/orders/${orderId}/checkouts`,
    {
      method: 'POST',
      headers: {
        ...headers,
        ...(accessToken ? { 'X-Auth': accessToken } : {}),
      },
      body: JSON.stringify(request),
    }
  );

  if (response.status === 402) {
    // Handle insufficient funds error specifically
    throw new Error(
      `Failed to create checkout for order ${orderId}, insufficient funds (status code 402)`
    );
  }

  if (!response.ok) {
    throw new Error(
      `Failed to create checkout for order ${orderId}, status code ${response.status}`
    );
  }

  return response.json();
};

export const resendReceipt = async ({
  consumerData,
  paymentId,
}: {
  consumerData: ConsumerDataType | null;
  paymentId: string;
}): Promise<number | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);

  if (!accessToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/payments/${paymentId}/resendReceipt`,
    {
      method: 'POST',
      headers: {
        ...headers,
        'X-Auth': accessToken,
      },
    }
  );

  return response.status;
};

export const createOrUpdateMyOpenCheckoutRequest = async ({
  consumerData,
  locationId,
  paymentRequestId,
  selectedItems,
}: {
  consumerData: ConsumerDataType | null;
  locationId?: string;
  paymentRequestId?: string;
  selectedItems: Array<{ uuid: string; quantity: number }>;
}): Promise<number | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;

  const response = await fetch(
    `${API_PATH}/v1/locations/${locationId}/paymentRequests/${paymentRequestId}/splitSessions/me`,
    {
      method: 'PATCH',
      body: JSON.stringify({ metadata: { lineItems: selectedItems } }),
      headers: { ...headers, 'X-Auth': accessToken },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.status;
};

export const deleteMyOpenCheckoutRequest = async ({
  consumerData,
  locationId,
  paymentRequestId,
}: {
  consumerData: ConsumerDataType | null;
  locationId?: string;
  paymentRequestId?: string;
}): Promise<number | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;

  const response = await fetch(
    `${API_PATH}/v1/locations/${locationId}/paymentRequests/${paymentRequestId}/splitSessions/me`,
    {
      method: 'DELETE',
      headers: { ...headers, 'X-Auth': accessToken },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.status;
};

export const getMyOpenCheckoutRequest = async ({
  consumerData,
  locationId,
  paymentRequestId,
}: {
  consumerData: ConsumerDataType | null;
  locationId?: string;
  paymentRequestId?: string;
}): Promise<{
  metadata: { lineItems: { uuid: string; quantity: number }[] };
  expiresAt: number;
} | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;

  const response = await fetch(
    `${API_PATH}/v1/locations/${locationId}/paymentRequests/${paymentRequestId}/splitSessions/me`,
    {
      method: 'GET',
      headers: { ...headers, 'X-Auth': accessToken },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getOtherOpenCheckoutRequests = async ({
  consumerData,
  locationId,
  paymentRequestId,
}: {
  consumerData: ConsumerDataType | null;
  locationId?: string;
  paymentRequestId?: string;
}): Promise<
  | {
      metadata: { lineItems: { uuid: string; quantity: number }[] };
    }[]
  | null
> => {
  const consumerAccessToken = await getAccessTokenForRequest(consumerData);

  if (!consumerAccessToken) {
    return null;
  }
  const response = await fetch(
    `${API_PATH}/v1/locations/${locationId}/paymentRequests/${paymentRequestId}/splitSessions/others`,
    {
      method: 'GET',
      headers: {
        ...headers,
        'X-Auth': consumerAccessToken,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getConsumerPaymentMethodsV2 = async (
  consumerData: ConsumerDataType
): Promise<PaymentMethodV2ResponseType | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);

  if (!accessToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v2/consumers/me/paymentMethods`, {
    headers: {
      ...headers,
      'X-Auth': accessToken,
    },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to get payment methods, status code ${response.status}`
    );
  }

  return response.json();
};

export const getConsumerPaymentMethodsV3 = async (
  consumerData: ConsumerDataType
): Promise<ConsumerPaymentMethodV3Type[] | null> => {
  const consumerAccessToken = await getAccessTokenForRequest(consumerData);

  if (!consumerAccessToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v3/consumers/me/paymentMethods`, {
    headers: {
      ...headers,
      'X-Auth': consumerAccessToken,
    },
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getOpenTablesForSlots = async ({
  consumerAccessToken,
}: {
  consumerAccessToken?: string | null;
}): Promise<GetOpenTablesForSlotsResponseType | null> => {
  const response = await fetch(
    `${API_PATH}/v3/consumers/showBundles/showOccurrences/openTables?accessToken=${consumerAccessToken}`,
    {
      method: 'GET',
      headers: {
        ...headers,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getOrdersForConsumer = async (
  consumerData: ConsumerDataType | null,
  consumerAccessToken?: string | null
): Promise<GetOrdersV3ResponseType | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v3/consumers/orders`, {
    headers: {
      ...headers,
      'X-Auth': consumerToken,
    },
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getReservationFolios = async ({
  pmsReference,
  discoverId,
  consumerData,
  consumerAccessToken,
}: {
  discoverId?: string;
  pmsReference?: string | null;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
}): Promise<GetCheckoutFolioResponseType | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken || !pmsReference) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/folios`,
    {
      headers: {
        ...headers,
        'X-Auth': consumerToken,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const createCheckin = async ({
  discoverId,
  pmsReference,
  consumerData,
  consumerAccessToken,
}: {
  discoverId: string;
  pmsReference?: string | null;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
}) => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken || !pmsReference) {
    return null;
  }

  return fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/checkin`,
    {
      method: 'POST',
      headers: {
        ...headers,
        'X-Auth': consumerToken,
      },
    }
  );
};

export const createStayPayment = async ({
  discoverId,
  pmsReference,
  consumerData,
  consumerAccessToken,
  requestPayload,
}: {
  discoverId: string;
  pmsReference?: string | null;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
  requestPayload: {
    payment?: CreateCheckoutV3PaymentInfoType;
    tipAmount: number;
    invoiceAmount?: number;
  };
}): Promise<CreateCheckoutResponse | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken || !pmsReference) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/payments`,
    {
      method: 'POST',
      body: JSON.stringify(requestPayload),
      headers: {
        ...headers,
        'X-Auth': consumerToken,
      },
    }
  );
  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const createStayCheckout = async ({
  discoverId,
  pmsReference,
  consumerData,
  consumerAccessToken,
  requestPayload,
}: {
  discoverId: string;
  pmsReference?: string | null;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
  requestPayload: {
    tipAmount: number;
  };
}): Promise<Response | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken || !pmsReference) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/checkout`,
    {
      method: 'POST',
      body: JSON.stringify(requestPayload),
      headers: {
        ...headers,
        'X-Auth': consumerToken,
      },
    }
  );
  if (!response.ok) {
    throw new ApiError(response);
  }

  return response;
};

export const createStayPreCheckin = async ({
  consumerData,
  requestPayload,
  pmsReference,
  discoverId,
}: {
  consumerData: ConsumerDataType | null;
  requestPayload: {
    payment: CreateCheckoutV3PaymentInfoType;
    tipAmount: number;
    invoiceAmount?: number;
  };
  pmsReference?: string | null;
  discoverId: string;
}): Promise<CreateCheckoutResponse | null> => {
  const accessToken = await getAccessTokenForRequest(consumerData);
  if (!accessToken) return null;

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/pre-checkin`,
    {
      method: 'POST',
      headers: {
        ...headers,
        'X-Auth': accessToken,
      },
      body: JSON.stringify(requestPayload),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to create pre-checkin, status code ${response.status}`
    );
  }
  return (await response.json()) as CreateCheckoutResponse;
};

export const createOrderForConsumer = async ({
  locationId,
  items,
  jwt,
}: {
  locationId?: string | null;
  items: SerializedOrderItemType[];
  jwt: string;
}) => {
  const response = await fetch(`${API_PATH}/v3/operators/orders`, {
    method: 'POST',
    body: JSON.stringify({
      locationId,
      items,
    }),
    headers: { ...headers, 'X-Auth': jwt },
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const searchForMenuItems = async ({
  locationId,
  language,
  name,
}: {
  locationId: string;
  language: SupportedLanguageEnum;
  name: string;
}): Promise<{ items: string[] }> => {
  const searchParameter = `?${convertToSearchParametersString({
    language,
    name,
  })}`;

  const response = await fetch(
    `${API_PATH}/v3/public/locations/${locationId}/menu/items/search${searchParameter}`,
    {
      headers: {
        ...headers,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getOrderSubIdForLocation = async ({
  locationId,
  apiKey,
}: {
  locationId: string | undefined;
  apiKey: string | null;
}): Promise<{ subscriptionId: string }> => {
  const jwt = await getJwtForLinkDevice(apiKey);
  const response = await fetch(
    `${API_PATH}/v3/public/locations/${locationId}/orders/subscribe`,
    {
      method: 'POST',
      headers: { ...headers, 'X-AUTH': jwt || '' },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const linkReservationToConsumer = async ({
  pmsReference,
  primaryGuestLastName,
  consumerAccessToken,
  consumerData,
  discoverId,
}: {
  pmsReference: string;
  primaryGuestLastName: string;
  discoverId: string;
  consumerAccessToken: string | null;
  consumerData: ConsumerDataType | null;
}): Promise<LinkedReservation | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(`${API_PATH}/v3/consumers/me/reservations`, {
    method: 'POST',
    headers: {
      ...headers,
      'X-Auth': consumerToken,
    },
    body: JSON.stringify({ pmsReference, primaryGuestLastName, discoverId }),
  });

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getActiveStayReservationsForConsumer = async ({
  consumerAccessToken,
  consumerData,
  discoverId,
}: {
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
  discoverId?: string;
}): Promise<GetActiveConsumerReservationsResponseType | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations`,
    {
      method: 'GET',
      headers: {
        ...headers,
        'X-Auth': consumerToken,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getReservationOrdersForConsumer = async ({
  pmsReference,
  consumerAccessToken,
  consumerData,
}: {
  pmsReference?: string | null;
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
}): Promise<GetOrdersV3ResponseType | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/reservations/${pmsReference}/order`,
    {
      method: 'GET',
      headers: {
        ...headers,
        'X-Auth': consumerToken,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const createReservationOrder = async ({
  consumerAccessToken,
  consumerData,
  discoverId,
  pmsReference,
  items,
  note,
}: {
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
  pmsReference: string | null;
  discoverId?: string | null;
  items: SerializedOrderItemType[];
  note?: string | null;
}) => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken || !discoverId || !pmsReference) {
    console.error('Unable to create order', {
      consumerToken,
      consumerData,
      discoverId,
      pmsReference,
      items,
      note,
    });
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/order`,
    {
      method: 'POST',
      body: JSON.stringify({
        items,
        ...(note !== null ? { note } : {}),
      }),
      headers: { ...headers, 'X-Auth': consumerToken },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getGroupSettingsByDiscoverId = async ({
  discoverId,
}: {
  discoverId: string | undefined;
}): Promise<StayLocationGroupSettingsType | null> => {
  const response = await fetch(
    `${API_PATH}/v3/public/discover/${discoverId}/stayConfig`,
    {
      method: 'GET',
      headers: {
        ...headers,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getConsumerHotelGuestWishOptions = async (
  locationId: string,
  consumerData: ConsumerDataType | null
): Promise<HotelGuestWishResponseType[]> => {
  const accessToken = await getAccessTokenForRequest(consumerData);

  if (!accessToken) {
    throw new Error('Unauthorized: No access token');
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/locations/${locationId}/hotelGuestWishes`,
    {
      headers: {
        ...headers,
        'X-Auth': accessToken,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getConsumerHotelGuestWishesForReservation = async ({
  discoverId,
  pmsReference,
  consumerData,
  consumerAccessToken,
}: {
  discoverId: string;
  pmsReference: string;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
}): Promise<ConsumerHotelGuestWishResponseType[] | null> => {
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/consumerHotelGuestWish`,
    {
      headers: {
        'X-Auth': consumerToken,
        ...headers,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const submitConsumerHotelGuestWish = async ({
  discoverId,
  pmsReference,
  consumerData,
  consumerAccessToken,
  wishData,
}: {
  discoverId: string;
  pmsReference: string;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
  wishData: HotelGuestWishRequestType;
}) => {
  // TODO: Wishes - Clarify if consumerAccessToken is or should be passed in any case?
  let consumerToken = consumerAccessToken;

  if (!consumerToken) {
    consumerToken = await getAccessTokenForRequest(consumerData);
  }

  if (!consumerToken) {
    return null;
  }

  const response = await fetch(
    `${API_PATH}/v3/consumers/discover/${discoverId}/reservations/${pmsReference}/consumerHotelGuestWish`,
    {
      method: 'POST',
      headers: {
        ...headers,
        'X-Auth': consumerToken,
      },
      body: JSON.stringify(wishData),
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const downloadPaymentReceiptPdf = async ({
  shopperReceiptsAccessToken,
}: {
  shopperReceiptsAccessToken?: string | null;
}) => {
  const searchParameter = `?${convertToSearchParametersString({
    accessToken: shopperReceiptsAccessToken || '',
  })}`;
  const response = await fetch(
    `${API_PATH}/v3/public/payments/shopperReceipts${searchParameter}`,
    {
      method: 'GET',
      headers: { ...headers },
    }
  );

  if (!response.ok) {
    throw new Error(
      `Could not get pdf for payment receipt ${downloadPaymentReceiptPdf} - Description: Network response was not ok`
    );
  }
  const contentDisposition = response.headers.get('content-disposition');
  const filenamePattern = /filename\*=utf-8''([^;]+)/i;
  const filenameMatch =
    contentDisposition && contentDisposition.match(filenamePattern);
  const filename = filenameMatch
    ? decodeURI(filenameMatch[1])
    : `shopperReceipt_${shopperReceiptsAccessToken}.pdf`;
  const blob = await response.blob();

  saveAs(blob, filename);
};

export const getReceiptPayment = async (
  shopperReceiptsAccessToken?: string | null
): Promise<ReceiptPaymentType | null> => {
  const searchParameter = `?${convertToSearchParametersString({
    accessToken: shopperReceiptsAccessToken || '',
  })}`;
  const response = await fetch(
    `${API_PATH}/v3/public/payments${searchParameter}`,
    {
      method: 'GET',
      headers: {
        ...headers,
      },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const sendReceiptToEmail = async ({
  shopperReceiptsAccessToken,
  email,
}: {
  shopperReceiptsAccessToken?: string | null;
  email: string;
}) => {
  const response = await fetch(
    `${API_PATH}/v3/public/payments/shopperReceipts/email?${convertToSearchParametersString(
      { accessToken: shopperReceiptsAccessToken || '' }
    )}`,
    {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({ email }),
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response;
};
