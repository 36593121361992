import {
  ConsumerDataType,
  DepricatedConsumerDataType,
  StayUserDataType,
} from 'types';
import { getAuthenticationCode } from 'network/payApi';
import {
  CONSUMER_LOCAL_STORAGE_KEY,
  STAY_USER_LOCAL_STORAGE_KEY,
  STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY,
  STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY,
} from 'constants/localStorage';

import { isAndroidWebView, isIOSWebView } from './webview';

declare const Android: {
  getConsumerAccessToken: () => string;
};

declare const IOS: {
  getConsumerAccessToken: () => string;
};

export const checkForOldConsumerData = (consumer: DepricatedConsumerDataType) =>
  consumer?.accessToken || consumer?.expirationTime || consumer?.email;

export const getAccessTokenForRequest = async (
  consumerData: ConsumerDataType | null
) => {
  if (!consumerData) {
    console.error('Error retrieving consumer accessToken - no consumerData');

    // TODO: Consumer access token - Clarify, where this is handled and whether to refactor
    throw new Error(
      'Error retrieving consumer accessToken due to missing consumer'
    );
  }

  try {
    const { accessToken } = await getAuthenticationCode(
      consumerData.username,
      consumerData.password
    );

    return accessToken;
  } catch (error: any) {
    console.error(`Error retrieving accessToken - ${error}`);
    return null;
  }
};

export const getConsumerAccessToken = async () => {
  let token = null;

  try {
    if (isAndroidWebView()) {
      token = await Android.getConsumerAccessToken();
    }
    if (isIOSWebView()) {
      token = await IOS.getConsumerAccessToken();
    }
  } catch (error) {
    console.error('Cannot get token from app system', error);
  }

  if (!token && window.location.hash) {
    token = window.location.hash.slice(1);
  }

  return token;
};

export const getConsumerFromLocalStorage = (): ConsumerDataType | null => {
  const storageItem = window.localStorage.getItem(CONSUMER_LOCAL_STORAGE_KEY);
  return storageItem ? (JSON.parse(storageItem) as ConsumerDataType) : null;
};

export const getStayUserFromLocalStorage = (): StayUserDataType | null => {
  const storageItem = window.localStorage.getItem(STAY_USER_LOCAL_STORAGE_KEY);
  return storageItem ? (JSON.parse(storageItem) as StayUserDataType) : null;
};

export const setStayUserFromLocalStorage = (value: any) => {
  const storageItem = window.localStorage.getItem(STAY_USER_LOCAL_STORAGE_KEY);
  const storedObject = storageItem ? JSON.parse(storageItem) : {};

  window.localStorage.setItem(
    STAY_USER_LOCAL_STORAGE_KEY,
    JSON.stringify({ ...storedObject, ...value })
  );
};

export const getIsUnknownUserFromLocalStorage = (): boolean | null => {
  const storageItem = window.localStorage.getItem(
    STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY
  );
  return storageItem ? (JSON.parse(storageItem) as boolean) : null;
};

export const setIsUnknownUserFromLocalStorage = (value: boolean) => {
  window.localStorage.setItem(
    STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY,
    JSON.stringify(value)
  );
};

export const getSelectedReservationReferenceFromLocalStorage = ():
  | string
  | null => {
  const storageItem = window.localStorage.getItem(
    STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY
  );
  return storageItem ? (JSON.parse(storageItem) as string) : null;
};

export const setSelectedReservationReferenceToLocalStorage = (
  value: string
) => {
  window.localStorage.setItem(
    STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY,
    JSON.stringify(value)
  );
};

export const removeKeyFromLocalStorage = (key: string) =>
  window.localStorage.removeItem(key);
