/* eslint-disable complexity */
import { createContainer } from 'unstated-next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PaymentsContainerTypes, SupportedCurrency } from 'types';
import {
  useGetCampaignsForLocation,
  useGetLocation,
  useGetLocationGroupConfig,
  useGetLocationGroupSettingsByLocationId,
  useGetOrderById,
  useGetOpenPaymentRequestsForLocationTable,
  useGetLocationGroupPaymentSettingsV3,
} from 'hooks';
import { getReferer, isValidUUID, useGetHighestDiscountCampaign } from 'utils';
import { LUCA_PAY_PATH } from 'constants/routes';
import { useEffect, useState } from 'react';
import { LUCA_PAY_ENTRY_URL } from 'constants/sessionStorage';
import { useConsumerContext } from 'context';
import { useMountEffect } from '@react-hookz/web';

const usePaymentsContainer = (): PaymentsContainerTypes => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { orderId } = useParams();
  const [searchParameters] = useSearchParams();
  const referer = getReferer(searchParameters);
  const table = searchParameters.get('table');
  const isHotelOrdering = searchParameters.get('isHotelOrdering');
  const { consumerData } = useConsumerContext();

  const [locationIdToBeUsed, setLocationIdToBeUsed] = useState<
    string | undefined
  >(locationId);

  const [selectedAmountToPay, setSelectedAmountToPay] = useState<
    number | undefined
  >();
  const [selectedSplitMethod, setSelectedSplitMethod] = useState<
    string | undefined
  >();
  const [selectedNumberToSplitEvenly, setSelectedNumberToSplitEvenly] =
    useState<number | undefined>();

  useMountEffect(() => {
    // Store current url in local storage to get it back in case of Rapyd-Cancel
    window.sessionStorage.setItem(
      LUCA_PAY_ENTRY_URL,
      JSON.stringify({ lucaPayEntryURL: window.location.href })
    );
  });

  const { data: orderResponse, isLoading: isOrderLoading } = useGetOrderById({
    orderId,
    consumerData,
  });

  useEffect(() => {
    if (!isOrderLoading && orderResponse?.locationId) {
      setLocationIdToBeUsed(orderResponse?.locationId);
    }
  }, [isOrderLoading, orderResponse]);

  const {
    data: paymentSettings,
    error: paymentSettingsError,
    isSuccess: isPaymentSettingsLoaded,
  } = useGetLocationGroupSettingsByLocationId(locationIdToBeUsed);

  const {
    data: locationInfo,
    error: locationInfoError,
    isLoading: isLocationInfoLoading,
  } = useGetLocation(locationIdToBeUsed);

  const {
    data: campaigns,
    error: campaignsError,
    isLoading: isCampaignsLoading,
  } = useGetCampaignsForLocation({
    locationId: locationIdToBeUsed,
    consumerData,
  });

  const {
    data: locationPaymentRequest,
    error: locationPaymentRequestError,
    isLoading: isPaymentRequestLoading,
    refetch: refetchPaymentRequest,
  } = useGetOpenPaymentRequestsForLocationTable({
    locationId: locationIdToBeUsed,
    tableId: table,
    enabled: !isLocationInfoLoading && locationInfo?.locationType !== 'hotel',
  });

  const { data: config, isLoading: isConfigLoading } =
    useGetLocationGroupConfig(consumerData, locationInfo?.discoverId);

  const {
    data: paymentGroupSettings,
    isLoading: isPaymentGroupSettingsLoading,
    error: paymentGroupSettingsError,
  } = useGetLocationGroupPaymentSettingsV3(locationInfo?.groupId);

  const highestDiscountCampaign = useGetHighestDiscountCampaign(campaigns);

  const paymentsDisabled =
    isPaymentSettingsLoaded && !paymentSettings?.paymentActive;

  const hasError =
    locationInfoError ||
    paymentSettingsError ||
    paymentGroupSettingsError ||
    campaignsError;

  if (
    (!locationId && !orderId) ||
    (locationId && !isValidUUID(locationId)) ||
    (orderId && !isValidUUID(orderId)) ||
    hasError
  ) {
    navigate(LUCA_PAY_PATH);
    throw new Error(
      `Payments: locationId nor orderId not set or invalid. Or at least one important query errored - hasError: ${hasError}`
    );
  }

  if (orderId && !isOrderLoading && !orderResponse?.invoiceAmount) {
    console.error('Payments: No amount for orderResponse');
    navigate(LUCA_PAY_PATH);
  }

  return {
    locationId: locationIdToBeUsed,
    orderInfo: orderResponse,
    paymentSettings,
    location: locationInfo,
    campaigns,
    config,
    paymentLimits: {
      minInvoiceAmount: paymentGroupSettings?.minInvoiceAmount || null,
      maxInvoiceAmount: paymentGroupSettings?.maxInvoiceAmount || null,
      maxTipAmount: paymentGroupSettings?.maxTipAmount || null,
    },
    currency: paymentGroupSettings?.currency || SupportedCurrency.EUR,
    highestDiscountCampaign,
    locationPaymentRequest,
    table,
    referer,
    paymentSettingsError,
    locationPaymentRequestError,
    locationInfoError,
    paymentGroupSettingsError,
    isPaymentGroupSettingsLoading,
    isPaymentSettingsLoaded,
    isCampaignsLoading,
    paymentsDisabled,
    isConfigLoading,
    isPaymentRequestLoading,
    isLocationInfoLoading,
    refetchPaymentRequest,
    isHotelOrdering: !!isHotelOrdering,
    splitPayment: {
      selectedAmountToPay,
      setSelectedAmountToPay,
      selectedSplitMethod,
      setSelectedSplitMethod,
      selectedNumberToSplitEvenly,
      setSelectedNumberToSplitEvenly,
    },
    isHotelLocation: locationInfo?.locationType === 'hotel' || false,
  };
};

export const PaymentsContainer = createContainer(usePaymentsContainer);
