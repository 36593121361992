/* eslint-disable max-lines */
import { IntlShape } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import { schengenRaumCountries, GERMAN_COUNTRY_CODE } from 'constants/stay';
import {
  STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY,
  STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY,
} from 'constants/localStorage';
import {
  germanDateStringFormat,
  stayIncomingStringFormat,
} from 'constants/time';
import {
  FolioEnrichedStayBookingType,
  FolioItemType,
  HotelReservationStatusEnum,
  IdentificationTypeEnum,
  InvoiceAddressStayType,
  NextPossibleActionType,
  PersonalDataStayType,
  ResidentialAddressStayType,
  StayAdditionalGuestType,
  StayPrimaryGuestType,
  StayUserDataType,
} from 'types';
import { STAY_PATH } from 'constants/routes';
import { getLanguage } from 'utils';

import {
  GENERIC_REQUIRED_ERROR,
  INVALID_FORMAT_ERROR,
  MAX_LENGTH_ERROR,
  MIN_LENGTH_ERROR,
} from './yup';

export const areAllFieldsFilled = (
  data:
    | PersonalDataStayType
    | ResidentialAddressStayType
    | InvoiceAddressStayType
    | undefined
) => {
  if (!data) return false;
  const dataLength = Object.keys(data).length;

  return (
    Object.values(data).length === dataLength &&
    Object.values(data).every(value => !!value)
  );
};

export const areAllMandatoryAddressFieldsFilled = (
  data?: ResidentialAddressStayType
): boolean => {
  if (!data) return false;

  const mandatoryFields: (keyof ResidentialAddressStayType)[] = [
    'addressLine1',
    'postalCode',
    'city',
    'countryCode',
    'state',
  ];

  return mandatoryFields.every(
    field =>
      Object.prototype.hasOwnProperty.call(data, field) && data[field] !== ''
  );
};

export const isFromSchengenRaum = (nationality?: string) =>
  !!nationality && schengenRaumCountries.includes(nationality);

const getFirstName = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) =>
  primaryGuest.firstName || storedPrimaryGuest?.firstName
    ? { firstName: primaryGuest.firstName || storedPrimaryGuest?.firstName }
    : {};

const getMiddleInitial = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) =>
  primaryGuest.middleInitial || storedPrimaryGuest?.middleInitial
    ? {
        middleInitial:
          primaryGuest.middleInitial || storedPrimaryGuest?.firstName,
      }
    : {};

const getLastName = (primaryGuest: StayPrimaryGuestType) =>
  primaryGuest.lastName ? { lastName: primaryGuest.lastName } : {};

const getEmail = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) =>
  primaryGuest.email || storedPrimaryGuest?.email
    ? { email: primaryGuest.email || storedPrimaryGuest?.email }
    : {};

const getPhone = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) =>
  primaryGuest.phone || storedPrimaryGuest?.phone
    ? { phone: primaryGuest.phone || storedPrimaryGuest?.phone }
    : {};

const getBirthDate = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) =>
  primaryGuest.birthDate || storedPrimaryGuest?.birthDate
    ? {
        birthDate: primaryGuest.birthDate
          ? dayjs(
              primaryGuest.birthDate,
              stayIncomingStringFormat,
              true
            ).format(germanDateStringFormat)
          : storedPrimaryGuest?.birthDate,
      }
    : {};

const getNationality = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) =>
  primaryGuest.nationalityCountryCode || storedPrimaryGuest?.nationality
    ? {
        nationality:
          primaryGuest.nationalityCountryCode ||
          storedPrimaryGuest?.nationality,
      }
    : {};

const getSalutation = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) =>
  primaryGuest.title || storedPrimaryGuest?.title
    ? {
        title: primaryGuest.title || storedPrimaryGuest?.title,
      }
    : {};

export const getIdentityDocument = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) => ({
  ...(primaryGuest.identificationIssueDate ||
  storedPrimaryGuest?.identityDocument?.issueDate
    ? {
        issueDate: primaryGuest.identificationIssueDate
          ? dayjs(
              primaryGuest.identificationIssueDate,
              stayIncomingStringFormat,
              true
            ).format(germanDateStringFormat)
          : storedPrimaryGuest?.identityDocument?.issueDate,
      }
    : {}),
  ...(primaryGuest.identificationExpiryDate ||
  storedPrimaryGuest?.identityDocument?.expireDate
    ? {
        expireDate: primaryGuest.identificationExpiryDate
          ? dayjs(
              primaryGuest.identificationExpiryDate,
              stayIncomingStringFormat,
              true
            ).format(germanDateStringFormat)
          : storedPrimaryGuest?.identityDocument?.expireDate,
      }
    : {}),
  ...(primaryGuest.identificationType ||
  storedPrimaryGuest?.identityDocument?.type
    ? {
        type:
          primaryGuest.identificationType ||
          storedPrimaryGuest?.identityDocument?.type,
      }
    : {}),
  ...(primaryGuest.identificationNumber ||
  storedPrimaryGuest?.identityDocument?.documentNumber
    ? {
        documentNumber:
          primaryGuest.identificationNumber ||
          storedPrimaryGuest?.identityDocument?.documentNumber,
      }
    : {}),
  ...(primaryGuest.birthPlace ||
  storedPrimaryGuest?.identityDocument?.birthPlace
    ? {
        birthPlace:
          primaryGuest.birthPlace ||
          storedPrimaryGuest?.identityDocument?.birthPlace,
      }
    : {}),
  ...(primaryGuest.identificationIssuingAuthority ||
  storedPrimaryGuest?.identityDocument?.identificationIssuingAuthority
    ? {
        identificationIssuingAuthority:
          primaryGuest.identificationIssuingAuthority ||
          storedPrimaryGuest?.identityDocument?.identificationIssuingAuthority,
      }
    : {}),
});

export const getPersonalDataFromBookingOrState = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) => ({
  ...getSalutation(primaryGuest, storedPrimaryGuest),
  ...getFirstName(primaryGuest, storedPrimaryGuest),
  ...getMiddleInitial(primaryGuest, storedPrimaryGuest),
  ...getLastName(primaryGuest),
  ...getEmail(primaryGuest, storedPrimaryGuest),
  ...getPhone(primaryGuest, storedPrimaryGuest),
  ...getBirthDate(primaryGuest, storedPrimaryGuest),
  ...getNationality(primaryGuest, storedPrimaryGuest),
  identityDocument: getIdentityDocument(primaryGuest, storedPrimaryGuest),
});

export const getResidentialDataFromBookingOrState = (
  primaryGuest: StayPrimaryGuestType,
  storedResidentialAddresses?: ResidentialAddressStayType[]
) => {
  const bookingAddress = primaryGuest.address;
  const addressFromBooking = bookingAddress
    ? {
        countryCode: bookingAddress.countryCode || GERMAN_COUNTRY_CODE,
        ...(bookingAddress.addressLine1
          ? { addressLine1: bookingAddress.addressLine1 }
          : {}),
        ...(bookingAddress.postalCode
          ? { postalCode: bookingAddress.postalCode }
          : {}),
        ...(bookingAddress.city ? { city: bookingAddress.city } : {}),
        ...(bookingAddress.state ? { state: bookingAddress.state } : {}),
      }
    : ({} as ResidentialAddressStayType);

  // If no local storage addresses are there just add the one from booking
  if (!storedResidentialAddresses || storedResidentialAddresses?.length === 0) {
    return [{ ...addressFromBooking, id: '0', isSelected: true }];
  }

  const possibleDuplicate = storedResidentialAddresses?.find(
    storedAddress =>
      !!addressFromBooking.addressLine1 &&
      storedAddress.addressLine1 === addressFromBooking.addressLine1
  );

  // If we find a possible duplicate based on the addressLine1 we select it
  if (possibleDuplicate) {
    const otherAddresses = storedResidentialAddresses
      ?.filter(
        storedAddress =>
          storedAddress.addressLine1 !== addressFromBooking.addressLine1
      )
      .map(otherAddress => ({ ...otherAddress, isSelected: false }));

    return [
      ...otherAddresses,
      {
        ...possibleDuplicate,
        ...addressFromBooking,
        id: `${storedResidentialAddresses.length}`,
        isSelected: true,
      },
    ];
  }

  // Return all stored addresses and add the new one as selected
  return [
    ...storedResidentialAddresses
      .filter(oldAddress => !areAllFieldsFilled(oldAddress))
      .map(oldAddress => ({
        ...oldAddress,
        isSelected: false,
      })),
    {
      ...addressFromBooking,
      id: `${storedResidentialAddresses.length}`,
      isSelected: true,
    },
  ];
};

export const getMonthMap = (intl: IntlShape) => ({
  '1': 'Jan',
  '2': 'Feb',
  '3': intl.formatMessage({ id: 'month.mar' }),
  '4': 'Apr',
  '5': intl.formatMessage({ id: 'month.may' }),
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': intl.formatMessage({ id: 'month.oct' }),
  '11': 'Nov',
  '12': intl.formatMessage({ id: 'month.dec' }),
});

export const getDateConfig = (monthMap: any) => ({
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
  month: {
    // @ts-ignore no types for the corresponding package (react-mobile-datepicker)
    format: (value: string) => monthMap[value.getMonth() + 1],
    caption: 'Mon',
    step: 1,
  },
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
});

export const getHasStoredData = (storedData: StayUserDataType | null) =>
  storedData?.personalData
    ? Object.keys(storedData?.personalData).length > 0
    : false;

export const getPersonalDataSalutationOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ id: 'generic.male' }),
    value: 'Mr',
    default: true,
  },
  {
    label: intl.formatMessage({ id: 'generic.ms' }),
    value: 'Ms',
  },
  {
    label: intl.formatMessage({ id: 'generic.female' }),
    value: 'Mrs',
  },
  {
    label: intl.formatMessage({ id: 'generic.dr' }),
    value: 'Dr',
  },
  {
    label: intl.formatMessage({ id: 'generic.prof' }),
    value: 'Prof',
  },
  {
    label: intl.formatMessage({ id: 'generic.other' }),
    value: 'Other',
  },
];

export const getInitialPersonalData = (
  storedData: StayUserDataType | null,
  intl: IntlShape
) => {
  const hasStoredData = getHasStoredData(storedData);
  return hasStoredData
    ? {
        ...storedData?.personalData,
        title:
          storedData?.personalData?.title ||
          getPersonalDataSalutationOptions(intl).find(option => option.default)
            ?.value,
        ...(storedData?.personalData.birthDate
          ? {
              birthDate: dayjs(
                storedData.personalData.birthDate,
                germanDateStringFormat,
                true
              ).format(germanDateStringFormat),
            }
          : {}),
      }
    : {};
};

export const getPersonalDataEditSchema = ({
  Yup,
  intl,
}: {
  Yup: any;
  intl: IntlShape;
}) =>
  yup.object().shape({
    title: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    firstName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    middleInitial: yup.string(),
    lastName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    birthDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    phone: Yup.phone,
    email: Yup.email,
  });

export const getResidentialDataEditSchema = ({
  Yup,
  intl,
}: {
  Yup: any;
  intl: IntlShape;
}) =>
  yup.object().shape({
    addressLine1: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    postalCode: Yup.zipCode.required(
      intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })
    ),
    city: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    countryCode: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  });

export const getInvoiceDataEditSchema = ({
  Yup,
  intl,
}: {
  Yup: any;
  intl: IntlShape;
}) =>
  yup.object().shape({
    companyName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    vat: yup.string(),
    addressLine1: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    postalCode: Yup.zipCode.required(
      intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })
    ),
    city: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    countryCode: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  });

export const getIdentificationDataEditSchema = ({
  intl,
}: {
  intl: IntlShape;
}) =>
  yup.object().shape({
    type: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    birthPlace: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    identificationIssuingAuthority: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    documentNumber: yup.string().when('type', (type, schema) =>
      typeof type === 'string' && type === 'PassportNumber'
        ? schema
            .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR }))
            .min(
              6,
              intl.formatMessage({
                id: MIN_LENGTH_ERROR,
                defaultMessage: 'Minimum length is 6 characters',
              })
            )
            .max(
              9,
              intl.formatMessage({
                id: MAX_LENGTH_ERROR,
                defaultMessage: 'Maximum length is 9 characters',
              })
            )
            .matches(
              /^[A-Z0-9]{6,9}$/,
              intl.formatMessage({
                id: INVALID_FORMAT_ERROR,
                defaultMessage: 'Invalid passport number format',
              })
            )
        : schema
            .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR }))
            .min(
              6,
              intl.formatMessage({
                id: MIN_LENGTH_ERROR,
                defaultMessage: 'Minimum length is 6 characters',
              })
            )
            .max(
              14,
              intl.formatMessage({
                id: MAX_LENGTH_ERROR,
                defaultMessage: 'Maximum length is 14 characters',
              })
            )
            .matches(
              /^[A-Z0-9]{6,14}$/,
              intl.formatMessage({
                id: INVALID_FORMAT_ERROR,
                defaultMessage: 'Invalid ID number format',
              })
            )
    ),
    issueDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    expireDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  });

export const getAdditionalGuestSchema = ({ intl }: { intl: IntlShape }) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    middleInitial: yup.string(),
    lastName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    birthDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    nationality: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    birthPlace: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    type: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    documentNumber: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    issueDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    expireDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  });

export const getSelectedResidentalAddress = (
  storedData: StayUserDataType | null
) =>
  storedData?.residentialData?.find(
    residentialAddress => residentialAddress.isSelected
  );

export const getNonSelectedResidentialAddresses = (
  storedData: StayUserDataType | null
) => {
  const selectedResidentialData = getSelectedResidentalAddress(storedData);
  return (
    storedData?.residentialData?.filter(address =>
      selectedResidentialData
        ? address.id !== selectedResidentialData?.id
        : false
    ) ?? []
  );
};

export const getSalutationToDisplay = ({
  title,
  intl,
}: {
  title?: string;
  intl: IntlShape;
}) =>
  getPersonalDataSalutationOptions(intl).find(
    salutation => salutation.value === title
  )?.label;

export const getPersonalDataRows = (
  storedData: StayUserDataType | null,
  intl: IntlShape
) => [
  {
    label: intl.formatMessage({
      id: 'generic.salutation',
    }),
    value: getSalutationToDisplay({
      title: storedData?.personalData.title,
      intl,
    }),
  },
  {
    label: intl.formatMessage({
      id: 'generic.firstName',
    }),
    value: storedData?.personalData.firstName,
  },
  {
    label: intl.formatMessage({
      id: 'generic.middleInitial',
    }),
    value: storedData?.personalData.middleInitial,
  },
  {
    label: intl.formatMessage({
      id: 'generic.lastName',
    }),
    value: storedData?.personalData.lastName,
  },
  {
    label: intl.formatMessage({
      id: 'generic.phone',
    }),
    value: storedData?.personalData.phone,
  },
  {
    label: intl.formatMessage({
      id: 'generic.email',
    }),
    value: storedData?.personalData.email,
  },
  {
    label: intl.formatMessage({
      id: 'generic.birthday',
    }),
    value: storedData?.personalData.birthDate,
  },
  {
    label: intl.formatMessage({
      id: 'generic.nationality',
    }),
    value: storedData?.personalData.nationality,
    noMargin: true,
  },
];

export const getResidentialAddressDataRows = (
  intl: IntlShape,
  address?: ResidentialAddressStayType
) => [
  {
    label: intl.formatMessage({
      id: 'generic.addressLine1',
    }),
    value: address?.addressLine1,
  },
  {
    label: intl.formatMessage({
      id: 'generic.city',
    }),
    value: `${address?.postalCode} ${address?.city}`,
  },
  {
    label: intl.formatMessage({
      id: 'generic.country',
    }),
    value: address?.countryCode,
    noMargin: true,
  },
];

export const getInvoiceAddressDataRows = (
  intl: IntlShape,
  address?: InvoiceAddressStayType
) => [
  {
    label: intl.formatMessage({
      id: 'generic.companyName',
    }),
    value: address?.companyName,
  },
  {
    label: intl.formatMessage({
      id: 'generic.vat',
    }),
    value: address?.vat,
  },
  {
    label: intl.formatMessage({
      id: 'generic.addressLine1',
    }),
    value: address?.addressLine1,
  },
  {
    label: intl.formatMessage({
      id: 'generic.city',
    }),
    value: `${address?.postalCode} ${address?.city}`,
  },
  {
    label: intl.formatMessage({
      id: 'generic.country',
    }),
    value: address?.countryCode,
    noMargin: true,
  },
];

export const getIdentityTypeOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ id: 'generic.passport' }),
    value: IdentificationTypeEnum.PASSPORT_NUMBER,
    default: true,
  },
  {
    label: intl.formatMessage({ id: 'generic.idNumber' }),
    value: IdentificationTypeEnum.ID_NUMBER,
  },
];

export const getInitialIdentityData = (
  storedData: StayUserDataType | null,
  intl: IntlShape
) =>
  storedData?.personalData?.identityDocument
    ? {
        ...storedData?.personalData?.identityDocument,
        type:
          storedData?.personalData?.identityDocument.type ||
          getIdentityTypeOptions(intl).find(option => option.default)?.value,
      }
    : {
        type: getIdentityTypeOptions(intl).find(option => option.default)
          ?.value,
      };

export const getInitialGuestData = (guest?: StayAdditionalGuestType) => {
  return {
    ...guest,
    birthDate: guest?.birthDate
      ? dayjs(guest?.birthDate).format(germanDateStringFormat)
      : '',
  };
};

export const getMainFolio = (folios?: FolioItemType[]) => {
  if (!folios) return null;
  return folios.find(folio => folio.isMainFolio === true) || folios.at(0);
};

export const getDepositFolio = (folios?: FolioItemType[]) => {
  if (!folios) return null;
  return folios.find(folio => folio.isDepositFolio === true) || folios.at(0);
};

export const getIsBeforeArrivalDate = ({
  arrivalDate,
}: {
  arrivalDate: Dayjs;
}) => dayjs(arrivalDate).isBefore(dayjs());

export const getIsCheckoutPossible = ({
  booking,
  dateFormat,
}: {
  booking?: FolioEnrichedStayBookingType | null;
  dateFormat: string;
}) =>
  dayjs(booking?.departureDate).format(dateFormat) ===
  dayjs().format(dateFormat);

export const getIsDepositPaid = ({
  booking,
}: {
  booking?: FolioEnrichedStayBookingType | null;
}) => {
  if (
    !booking?.deposit?.amount ||
    !booking?.depositFolio?.payments ||
    booking?.depositFolio?.payments.length === 0
  ) {
    return false;
  }

  // Currently the amount from the deposit is not equal to the payment in the deposit folio as the one ins USD and the other is EUR
  return (
    booking?.depositFolio?.payments.length > 0 &&
    booking?.depositFolio?.payments[0].amount > 0
  );
};

export const getNextPossibleActionForReservation = ({
  booking,
  intl,
  discoverId,
}: {
  booking?: FolioEnrichedStayBookingType | undefined | null;
  intl: IntlShape;
  discoverId: string;
}): NextPossibleActionType | null => {
  if (!booking) return null;
  const dateFormat =
    getLanguage() === 'de' ? germanDateStringFormat : 'YYYY-DD-MM';

  switch (booking.status) {
    case HotelReservationStatusEnum.CONFIRMED:
      if (!booking?.meldeschein?.uuid) {
        return {
          actionUrl: `${STAY_PATH}/${discoverId}/pre-checkin`,
          actionText: intl.formatMessage({
            id: 'lucaStay.nextAction.preCheckIn',
          }),
          actionDescription: intl.formatMessage({
            id: 'lucaStay.nextAction.preCheckIn.description',
          }),
          showBadge: true,
        };
      }
      return {
        actionUrl: `${STAY_PATH}/${discoverId}/pre-checkin`,
        actionText: intl.formatMessage({
          id: 'lucaStay.nextAction.preCheckIn',
        }),
        actionDescription: intl.formatMessage({
          id: 'lucaStay.nextAction.preCheckIn.description',
        }),
        showBadge: true,
      };

    case HotelReservationStatusEnum.PRE_CHECKEDIN:
      if (!booking.unitId) {
        return {
          actionUrl: null,
          actionText: null,
          actionDescription: intl.formatMessage({
            id: 'lucaStay.nextAction.checkIn.roomNotReady.description',
          }),
          showBadge: false,
        };
      }
      if (!getIsBeforeArrivalDate({ arrivalDate: booking.arrivalDate })) {
        return {
          actionUrl: null,
          actionText: null,
          actionDescription: intl.formatMessage({
            id: 'lucaStay.checkin.checkinNotReadyYet',
          }),
          showBadge: false,
        };
      }
      return {
        actionUrl: `${STAY_PATH}/${discoverId}/checkin`,
        actionText: intl.formatMessage({
          id: 'lucaStay.nextAction.checkIn',
        }),
        actionDescription: intl.formatMessage({
          id: 'lucaStay.nextAction.checkIn.description',
        }),
        showBadge: true,
      };
    case HotelReservationStatusEnum.IN_HOUSE:
      if (booking && booking.totalOpenAmount && booking.totalOpenAmount > 0) {
        return {
          actionUrl: `${STAY_PATH}/${discoverId}/checkout/payment`,
          actionText: intl.formatMessage({
            id: 'lucaStay.nextAction.payment',
          }),
          actionDescription: intl.formatMessage({
            id: 'lucaStay.nextAction.payment.description',
          }),
          showBadge: true,
        };
      }
      if (!getIsCheckoutPossible({ booking, dateFormat })) {
        return {
          actionUrl: `${STAY_PATH}/${discoverId}/checkout/payment`,
          actionText: intl.formatMessage({
            id: 'generic.paymentOverview',
          }),
          actionDescription: intl.formatMessage({
            id: 'lucaStay.nextAction.chargesPayedCheckedOutNotReady.description',
          }),
          showBadge: false,
        };
      }
      return {
        actionUrl: `${STAY_PATH}/${discoverId}/checkout`,
        actionText: intl.formatMessage({
          id: 'lucaStay.nextAction.checkOut',
        }),
        actionDescription: intl.formatMessage({
          id: 'lucaStay.nextAction.checkOut.description',
        }),
        showBadge: true,
      };
    case HotelReservationStatusEnum.CHECKED_OUT:
      return {
        actionUrl: null,
        actionText: null,
        actionDescription: intl.formatMessage({
          id: 'lucaStay.nextAction.alreadyCheckedOut.description',
        }),
        showBadge: false,
      };
    default:
      return null;
  }
};

export const getReservationStatus = ({
  intl,
  booking,
}: {
  intl: IntlShape;
  booking?: FolioEnrichedStayBookingType | null;
}) => {
  const hasCompletedMeldescheinBeforeCheckin =
    booking?.status === HotelReservationStatusEnum.CONFIRMED &&
    booking?.meldeschein?.uuid;

  if (!booking?.status) return '';

  if (hasCompletedMeldescheinBeforeCheckin) {
    return intl.formatMessage({
      id: 'hotelReservationStatus.preCheckinComplete',
    });
  }
  return intl.formatMessage({
    id: `hotelReservationStatus.${booking?.status}`,
  });
};

export const shouldRedirectToHome = ({
  discoverId,
  isUnknownUser,
  isPMSconnected,
  selectedReservationReference,
}: {
  discoverId: string;
  isUnknownUser: boolean;
  isPMSconnected: boolean;
  selectedReservationReference?: string | null;
}) => {
  const rootPath = `${STAY_PATH}/${discoverId}`;
  return !(
    window.location.pathname === rootPath ||
    isUnknownUser ||
    selectedReservationReference ||
    !isPMSconnected
  );
};

export const getDisplayName = (
  booking: FolioEnrichedStayBookingType | null | undefined
) =>
  booking?.primaryGuest.firstName
    ? `${booking?.primaryGuest.firstName} ${booking?.primaryGuest.lastName}`
    : booking?.primaryGuest.lastName;

export const toLogin = (discoverId: string) => {
  window.localStorage.removeItem(STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY);
  window.location.assign(`${STAY_PATH}/${discoverId}`);
};

export const onLogout = (discoverId: string) => {
  window.localStorage.removeItem(STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY);
  window.localStorage.removeItem(STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY);
  window.location.assign(`${STAY_PATH}/${discoverId}`);
};
