/* eslint-disable max-lines */
import { ConfigProvider } from 'antd';
import antdLocaleDE from 'antd/es/locale/de_DE';
import antdlocaleEL from 'antd/es/locale/el_GR';
import antdlocaleEN from 'antd/es/locale/en_US';
import { AppWrapper, PayAppWrapper } from 'App.styled';
import { DarkLoading, LoadingWrapper } from 'components/general';
import { Page } from 'components/Page';
import { PreorderRedirect } from 'components/Payments/PreorderPayments/PreorderRedirect';
import {
  APPLE_SSO_ERROR,
  APPLE_SSO_SUCCESS,
  BASE_PATH,
  CLUB_MEMBER_APPLICATION_PAGE_ROUTE,
  CLUB_MEMBER_REFERRAL_PAGE_ROUTE,
  EMAIL_ERROR,
  EMAIL_EXISTS_ERROR,
  EMAIL_SUCCESS,
  EXPERIENCES_PATH,
  EXPERIENCE_ROUTE,
  HOTEL_LANDINGPAGE_ROUTE,
  INSTALL_REDIRECT,
  LANDING_PAGE_ROUTE,
  LICENSES_ROUTE,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LUCA_CLUB_PAGE_ROUTE,
  LUCA_SHOPPER_RECEIPTS_ROUTE,
  LUCA_PAY_PATH,
  LUCA_PREORDER_ORDER_ROUTE,
  LUCA_STAY_ROUTE,
  MENU_PAGE_ROUTE,
  PRODUCT_MADE_REDIRECT,
  RESERVATIONS_CANCEL_ROUTE,
  RESERVATIONS_PAGE,
  SELF_CHECKOUT_KIOSK_ROUTE,
  SELF_CHECKOUT_PAY_ROUTE,
  SELF_CHECKOUT_ROUTE,
  SELF_CHECKOUT_SHOP_ROUTE,
  REDIRECT_TEST,
  SELF_CHECKOUT_SUCCESS_ROUTE,
  USER_EXISTS_ERROR,
  VOUCHER_REDEEM_ERROR,
  VOUCHER_REDEEM_EXISTS_ERROR,
  VOUCHER_REDEEM_SUCCESS,
} from 'constants/routes';
import { DEFAULT_TIMEZONE } from 'constants/time';
import {
  ApiKeyContainer,
  CheckoutContainer,
  HotelCheckinContainer,
  LocationScannerContainer,
  PaymentsContainer,
  PreorderPaymentContainer,
  SelfCheckoutContainer,
  StayContainer,
  StayUserContainer,
  ThemeContainer,
} from 'containers';
import { ShopperReceiptsContainer } from 'containers/ShopperReceiptsContainer';
import { ConsumerContextProvider } from 'context';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { useNetworkConnectionAlert } from 'hooks/network';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import 'moment/dist/locale/de';
import 'moment/dist/locale/en-gb';
import { lazy, Suspense } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { getLanguage } from 'utils';
import { DeepLinkPage } from 'components/DeepLinkPages/RedirectTestPage/RedirectTestPage.react';

const defaultLocale = getLanguage();

dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault(DEFAULT_TIMEZONE);
dayjs.locale(defaultLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

momentTimezone.tz(DEFAULT_TIMEZONE);
momentTimezone.tz.setDefault(DEFAULT_TIMEZONE);
moment.locale(defaultLocale);
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const InstallRedirect = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.InstallRedirect,
  }))
);
const ProductMadeRedirect = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.ProductMadeRedirect,
  }))
);
const ExperiencesLandingPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.ExperiencesLandingPage,
  }))
);
const EmailErrorPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.EmailErrorPage,
  }))
);
const EmailExistsErrorPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.EmailExistsErrorPage,
  }))
);
const EmailSuccessPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.EmailSuccessPage,
  }))
);
const LoginErrorPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.LoginErrorPage,
  }))
);
const LoginSuccessPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.LoginSuccessPage,
  }))
);
const ReservationsPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.ReservationsPage,
  }))
);
const VoucherRedeemConfirmErrorPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.VoucherRedeemConfirmErrorPage,
  }))
);
const VoucherRedeemAlreadyConfirmedPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.VoucherRedeemAlreadyConfirmedPage,
  }))
);
const VoucherRedeemConfirmSuccessPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.VoucherRedeemConfirmSuccessPage,
  }))
);
const AppleSSOErrorPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.AppleSSOErrorPage,
  }))
);
const AppleSSOSuccessPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.AppleSSOSuccessPage,
  }))
);
const UserExistsErrorPage = lazy(() =>
  import('components/DeepLinkPages').then(module => ({
    default: module.UserExistsErrorPage,
  }))
);

const LucaPreorderOrderFlow = lazy(() =>
  import('components/LucaPreorder').then(module => ({
    default: module.LucaPreorderOrderFlow,
  }))
);

const Concierge = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.Concierge,
  }))
);

const StayCustomContent = lazy(() =>
  import('components/LucaStay/Concierge/CustomContentPage').then(module => ({
    default: module.CustomContent,
  }))
);

const StayHome = lazy(() =>
  import('components/LucaStay/Concierge/Home').then(module => ({
    default: module.Home,
  }))
);

const LucaStayInRoomOrdering = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.InRoomOrdering,
  }))
);

const LucaStayInRoomOrderingView = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.InRoomOrderingView,
  }))
);

const LucaStayRestaurantsNear = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.RestaurantsNear,
  }))
);

const LucaStayOrderOverview = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.OrderOverview,
  }))
);

const LucaStayHotelInformation = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.HotelInformation,
  }))
);

const LucaStayWishes = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.Wishes,
  }))
);

const LucaStayLanguageSwitch = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.LanguageSwitch,
  }))
);

const LucaStayReservations = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.Reservations,
  }))
);

const LucaStayLegalInformation = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.LegalInformation,
  }))
);

const LucaStayInRoomOrderPayment = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.InRoomOrderPayment,
  }))
);

const LucaStayInRoomOrderSuccess = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.InRoomOrderSuccess,
  }))
);

const LucaStayInRoomOrderError = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.InRoomOrderError,
  }))
);

const LucaStayPreCheckin = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.StayPreCheckin,
  }))
);

const LucaStayCheckin = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.StayCheckin,
  }))
);

const LucaStayAlreadyCheckedOut = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.StayAlreadyCheckedOut,
  }))
);

const LucaStayPayment = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.StayPayment,
  }))
);

const LucaStayCheckout = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.StayCheckout,
  }))
);

const LandingPage = lazy(() =>
  import('components/LandingPage').then(module => ({
    default: module.LandingPage,
  }))
);
const HotelLandingPage = lazy(() =>
  import('components/HotelLandingPage').then(module => ({
    default: module.HotelLandingPage,
  }))
);

const ConsumerSelfcheckout = lazy(() =>
  import('components/SelfCheckout').then(module => ({
    default: module.ConsumerSelfcheckout,
  }))
);

const SelfCheckoutPayment = lazy(() =>
  import('components/SelfCheckout').then(module => ({
    default: module.SelfCheckoutPayment,
  }))
);

const SelfCheckoutSuccess = lazy(() =>
  import('components/SelfCheckout').then(module => ({
    default: module.SelfCheckoutSuccess,
  }))
);

const SelfCheckoutKiosk = lazy(() =>
  import('components/SelfCheckout').then(module => ({
    default: module.SelfCheckoutKiosk,
  }))
);

const SelfCheckoutShop = lazy(() =>
  import('components/SelfCheckout').then(module => ({
    default: module.SelfCheckoutShop,
  }))
);

const LocationCheckIn = lazy(() =>
  import('components/LocationCheckIn').then(module => ({
    default: module.LocationCheckIn,
  }))
);

const MenuPage = lazy(() =>
  import('components/MenuPage').then(module => ({
    default: module.MenuPage,
  }))
);

const Licenses = lazy(() =>
  import('components/Licenses').then(module => ({
    default: module.Licenses,
  }))
);

const LucaClubPage = lazy(() =>
  import('components/LucaClubPage').then(module => ({
    default: module.LucaClubPage,
  }))
);

const ClubMemberReferralPage = lazy(() =>
  import('components/LucaClubPage').then(module => ({
    default: module.ClubMemberReferralPage,
  }))
);

const ClubMemberApplicationPage = lazy(() =>
  import('components/LucaClubPage').then(module => ({
    default: module.ClubMemberApplicationPage,
  }))
);

const LocationCheckoutCancel = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.LocationCheckoutCancel,
  }))
);

const LocationCheckoutError = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.LocationCheckoutError,
  }))
);
const LocationCheckoutSuccess = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.LocationCheckoutSuccess,
  }))
);
const RegisterAccount = lazy(() =>
  import('components/RegisterAccount').then(module => ({
    default: module.RegisterAccount,
  }))
);
const RegisterAccountSuccess = lazy(() =>
  import('components/RegisterAccountSuccess').then(module => ({
    default: module.RegisterAccountSuccess,
  }))
);
const LandingPageEmptyView = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.LandingPageEmptyView,
  }))
);
const BillView = lazy(() =>
  import('components/general/paymentComponents/PaymentView/BillView').then(
    module => ({
      default: module.BillView,
    })
  )
);

const HotelCheckoutPaymentView = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.HotelCheckoutPaymentView,
  }))
);

const ReservationsCancel = lazy(() =>
  import('components/ReservationsCancel').then(module => ({
    default: module.ReservationsCancel,
  }))
);
const VoucherPage = lazy(() =>
  import('components/VoucherPage').then(module => ({
    default: module.VoucherPage,
  }))
);
const LocationPayments = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.LocationPayments,
  }))
);
const PreorderPayments = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.PreorderPayments,
  }))
);
const OrderPayments = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.OrderPayments,
  }))
);

const PreorderCheckoutSuccess = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.PreorderCheckoutSuccess,
  }))
);

const HotelCheckoutSuccess = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.HotelCheckoutSuccess,
  }))
);

const StayCheckoutSuccess = lazy(() =>
  import('components/LucaStay').then(module => ({
    default: module.StayCheckoutSuccess,
  }))
);

const PreorderCheckoutError = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.PreorderCheckoutError,
  }))
);
const PreorderCancelOrderFlow = lazy(() =>
  import('components/Payments').then(module => ({
    default: module.PreorderCancelOrderFlow,
  }))
);

const ShopperReceipts = lazy(() =>
  import('components/ShopperReceipts').then(module => ({
    default: module.ShopperReceipts,
  }))
);

const ConsumerContextLayout = () => {
  return (
    <ConsumerContextProvider>
      <Outlet />
    </ConsumerContextProvider>
  );
};
export const App = () => {
  const intl = useIntl();
  useNetworkConnectionAlert();

  return (
    <ConfigProvider
      locale={(() => {
        switch (intl.locale) {
          case 'de':
            return antdLocaleDE;
          case 'el':
            return antdlocaleEL;
          default:
            return antdlocaleEN;
        }
      })()}
    >
      <Suspense
        fallback={
          <LoadingWrapper>
            <DarkLoading />
          </LoadingWrapper>
        }
      >
        <Routes>
          {/* Redirect from old /opera/preorder to new /preorder/:discoverId  */}
          <Route
            path="/opera/preorder"
            element={
              <Navigate
                replace
                to="/preorder/a111a4fa-a28e-4076-8ed4-52eda9ab2ed2"
              />
            }
          />
          <Route path={EXPERIENCES_PATH} element={<ConsumerContextLayout />}>
            <Route
              path={EXPERIENCES_PATH}
              element={
                <Page
                  title="Experiences.PageTitle"
                  legacyLocaleId="Experiences.PageTitle"
                >
                  <ExperiencesLandingPage />
                </Page>
              }
            />
            <Route
              path={EXPERIENCE_ROUTE}
              element={
                <Page
                  title="Experiences.PageTitle"
                  legacyLocaleId="Experiences.PageTitle"
                >
                  <ExperiencesLandingPage />
                </Page>
              }
            />
          </Route>
          <Route
            path={BASE_PATH}
            element={
              <Page title="Home.PageTitle" legacyLocaleId="Home.PageTitle">
                <LocationCheckIn />
              </Page>
            }
          />
          <Route path={BASE_PATH} element={<Outlet />}>
            <Route
              path={LICENSES_ROUTE}
              element={
                <Page
                  title="Licenses.PageTitle"
                  legacyLocaleId="Licenses.PageTitle"
                >
                  <AppWrapper>
                    <Licenses />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={INSTALL_REDIRECT}
              element={
                <Page
                  title="InstallRedirect.PageTitle"
                  legacyLocaleId="InstallRedirect.PageTitle"
                >
                  <AppWrapper>
                    <InstallRedirect />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={REDIRECT_TEST}
              element={
                <Page
                  title="InstallRedirect.PageTitle"
                  legacyLocaleId="InstallRedirect.PageTitle"
                >
                  <AppWrapper>
                    <DeepLinkPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={PRODUCT_MADE_REDIRECT}
              element={
                <Page
                  title="ProductMadeRedirect.PageTitle"
                  legacyLocaleId="ProductMadeRedirect.PageTitle"
                >
                  <AppWrapper>
                    <ProductMadeRedirect />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={EMAIL_ERROR}
              element={
                <Page
                  title="EmailError.PageTitle"
                  legacyLocaleId="EmailError.PageTitle"
                >
                  <AppWrapper>
                    <EmailErrorPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={EMAIL_EXISTS_ERROR}
              element={
                <Page
                  title="EmailExistsError.PageTitle"
                  legacyLocaleId="EmailExistsError.PageTitle"
                >
                  <AppWrapper>
                    <EmailExistsErrorPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={EMAIL_SUCCESS}
              element={
                <Page
                  title="EmailSuccessConfirmation.PageTitle"
                  legacyLocaleId="EmailSuccessConfirmation.PageTitl"
                >
                  <AppWrapper>
                    <EmailSuccessPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={VOUCHER_REDEEM_ERROR}
              element={
                <Page
                  title="VoucherRedeemError.PageTitle"
                  legacyLocaleId="VoucherRedeemError.PageTitle"
                >
                  <AppWrapper>
                    <VoucherRedeemConfirmErrorPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={VOUCHER_REDEEM_EXISTS_ERROR}
              element={
                <Page
                  title="VoucherRedeemExistsError.PageTitle"
                  legacyLocaleId="VoucherRedeemExistsError.PageTitle"
                >
                  <AppWrapper>
                    <VoucherRedeemAlreadyConfirmedPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={VOUCHER_REDEEM_SUCCESS}
              element={
                <Page
                  title="VoucherRedeemConfirmation.PageTitle"
                  legacyLocaleId="VoucherRedeemConfirmation.PageTitle"
                >
                  <AppWrapper>
                    <VoucherRedeemConfirmSuccessPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={LOGIN_ERROR}
              element={
                <Page
                  title="LoginError.PageTitle"
                  legacyLocaleId="LoginError.PageTitle"
                >
                  <AppWrapper>
                    <LoginErrorPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={LOGIN_SUCCESS}
              element={
                <Page
                  title="LoginSuccess.PageTitle"
                  legacyLocaleId="LoginSuccess.PageTitle"
                >
                  <AppWrapper>
                    <LoginSuccessPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route
              path={RESERVATIONS_PAGE}
              element={
                <Page
                  title="Reservations.PageTitle"
                  legacyLocaleId="Reservations.PageTitle"
                >
                  <AppWrapper>
                    <ReservationsPage />
                  </AppWrapper>
                </Page>
              }
            />
            <Route path={MENU_PAGE_ROUTE} element={<ConsumerContextLayout />}>
              <Route
                path={MENU_PAGE_ROUTE}
                element={
                  <Page title="Menu.PageTitle" legacyLocaleId="Menu.PageTitle">
                    <MenuPage />
                  </Page>
                }
              />
            </Route>

            <Route
              path={LANDING_PAGE_ROUTE}
              element={
                <Page
                  title="LandingPage.Title"
                  analyticsIdentifier="view.checkin"
                >
                  <LocationScannerContainer.Provider>
                    <LandingPage />
                  </LocationScannerContainer.Provider>
                </Page>
              }
            />
          </Route>
          <Route
            path={LUCA_CLUB_PAGE_ROUTE}
            element={
              <Page
                title="LucaClub.PageTitle"
                legacyLocaleId="LucaClub.PageTitle"
              >
                <LucaClubPage />
              </Page>
            }
          />
          <Route
            path={LUCA_SHOPPER_RECEIPTS_ROUTE}
            element={
              <Page
                title="LucaDiscover.PageTitle"
                legacyLocaleId="LucaDiscover.PageTitle"
              >
                <ShopperReceiptsContainer.Provider>
                  <ShopperReceipts />
                </ShopperReceiptsContainer.Provider>
              </Page>
            }
          />
          <Route
            path={LUCA_STAY_ROUTE}
            element={
              <ConsumerContextProvider>
                <Page
                  title="LucaStay.PageTitle"
                  legacyLocaleId="LucaStay.PageTitle"
                >
                  <StayContainer.Provider>
                    <StayUserContainer.Provider>
                      <Concierge />
                    </StayUserContainer.Provider>
                  </StayContainer.Provider>
                </Page>
              </ConsumerContextProvider>
            }
          >
            <Route path="" element={<StayHome />} />
            <Route path="content/:contentId" element={<StayCustomContent />} />

            <Route path="orderOverview" element={<LucaStayOrderOverview />} />
            <Route path="inRoomOrdering" element={<LucaStayInRoomOrdering />} />
            <Route
              path="restaurantsNear"
              element={<LucaStayRestaurantsNear />}
            />
            <Route path="wishes" element={<LucaStayWishes />} />

            <Route
              path="hotelInformation"
              element={<LucaStayHotelInformation />}
            />
            <Route
              path="legalInformation"
              element={<LucaStayLegalInformation />}
            />
            <Route path="language" element={<LucaStayLanguageSwitch />} />
            <Route path="reservations" element={<LucaStayReservations />} />
            <Route
              path="inRoomOrdering/:locationId"
              element={<LucaStayInRoomOrderingView />}
            />
            <Route
              path="inRoomOrder/:orderId"
              element={
                <PaymentsContainer.Provider>
                  <LucaStayInRoomOrderPayment />
                </PaymentsContainer.Provider>
              }
            />
            <Route
              path="checkout/:checkoutId/success"
              element={
                <CheckoutContainer.Provider>
                  <LucaStayInRoomOrderSuccess />
                </CheckoutContainer.Provider>
              }
            />
            <Route
              path="checkout/:checkoutId/error"
              element={
                <CheckoutContainer.Provider>
                  <LucaStayInRoomOrderError />
                </CheckoutContainer.Provider>
              }
            />

            <Route path="pre-checkin" element={<LucaStayPreCheckin />} />
            <Route path="checkin" element={<LucaStayCheckin />} />
            <Route
              path="checkout/amount"
              element={<HotelCheckoutPaymentView />}
            />
            <Route path="payment/success" element={<HotelCheckoutSuccess />} />
            <Route path="checkout/success" element={<StayCheckoutSuccess />} />
            <Route path="checkout/payment" element={<LucaStayPayment />} />
            <Route path="checkout" element={<LucaStayCheckout />} />
            <Route
              path="already-checked-out"
              element={<LucaStayAlreadyCheckedOut />}
            />
          </Route>
          <Route
            path={LUCA_PAY_PATH}
            element={
              <Page
                title="payment.home.pageTitle"
                legacyLocaleId="payment.home.pageTitle"
              >
                <PayAppWrapper>
                  <LandingPageEmptyView />
                </PayAppWrapper>
              </Page>
            }
          />
          <Route
            path={HOTEL_LANDINGPAGE_ROUTE}
            element={
              <ConsumerContextProvider>
                <Page
                  title="HotelLandingPage.Title"
                  legacyLocaleId="HotelLandingPage.Title"
                >
                  <HotelCheckinContainer.Provider>
                    <HotelLandingPage />
                  </HotelCheckinContainer.Provider>
                </Page>
              </ConsumerContextProvider>
            }
          />
          <Route
            path={SELF_CHECKOUT_ROUTE}
            element={
              <ConsumerContextProvider>
                <Page
                  title="SelfCheckoutPage.Title"
                  legacyLocaleId="SelfCheckoutPage.Title"
                >
                  <SelfCheckoutContainer.Provider>
                    <ConsumerSelfcheckout />
                  </SelfCheckoutContainer.Provider>
                </Page>
              </ConsumerContextProvider>
            }
          />
          <Route
            path={SELF_CHECKOUT_KIOSK_ROUTE}
            element={
              <Page
                title="SelfCheckoutPage.Title"
                legacyLocaleId="SelfCheckoutPage.Title"
              >
                <SelfCheckoutContainer.Provider>
                  <ApiKeyContainer.Provider>
                    <SelfCheckoutKiosk />
                  </ApiKeyContainer.Provider>
                </SelfCheckoutContainer.Provider>
              </Page>
            }
          />
          <Route
            path={SELF_CHECKOUT_SHOP_ROUTE}
            element={
              <Page
                title="SelfCheckoutPage.Title"
                legacyLocaleId="SelfCheckoutPage.Title"
              >
                <SelfCheckoutContainer.Provider>
                  <ApiKeyContainer.Provider>
                    <SelfCheckoutShop />
                  </ApiKeyContainer.Provider>
                </SelfCheckoutContainer.Provider>
              </Page>
            }
          />
          <Route
            path={SELF_CHECKOUT_PAY_ROUTE}
            element={
              <ConsumerContextProvider>
                <Page
                  title="SelfCheckoutPage.Title"
                  legacyLocaleId="SelfCheckoutPage.Title"
                >
                  <SelfCheckoutContainer.Provider>
                    <SelfCheckoutPayment />
                  </SelfCheckoutContainer.Provider>
                </Page>
              </ConsumerContextProvider>
            }
          />
          <Route
            path={SELF_CHECKOUT_SUCCESS_ROUTE}
            element={
              <ConsumerContextProvider>
                <Page
                  title="SelfCheckoutPage.Title"
                  legacyLocaleId="SelfCheckoutPage.Title"
                >
                  <SelfCheckoutContainer.Provider>
                    <CheckoutContainer.Provider>
                      <SelfCheckoutSuccess />
                    </CheckoutContainer.Provider>
                  </SelfCheckoutContainer.Provider>
                </Page>
              </ConsumerContextProvider>
            }
          />
          <Route path={LUCA_PAY_PATH} element={<ConsumerContextLayout />}>
            {/* Redirects from old opera paths  */}

            <Route
              path="opera/cancel-preorder/:bundleId/checkout/:checkoutId"
              element={<PreorderRedirect />}
            />
            <Route
              path="opera/cancel-preorder/:bundleId"
              element={<PreorderRedirect />}
            />
            <Route path="opera/:bundleId" element={<PreorderRedirect />} />

            <Route
              path=":locationId"
              element={
                <Page
                  title="payment.location.pageTitle"
                  legacyLocaleId="payment.location.pageTitle"
                >
                  <PaymentsContainer.Provider>
                    <LocationPayments />
                  </PaymentsContainer.Provider>
                </Page>
              }
            />
            <Route
              path="order/:orderId"
              element={
                <Page
                  title="payment.order.pageTitle"
                  legacyLocaleId="payment.order.pageTitle"
                >
                  <PaymentsContainer.Provider>
                    <OrderPayments />
                  </PaymentsContainer.Provider>
                </Page>
              }
            />
            <Route
              path="bill/:locationId"
              element={
                <Page
                  title="payment.amount.pageTitle"
                  analyticsIdentifier="view.payment.flow.split"
                >
                  <PaymentsContainer.Provider>
                    <BillView />
                  </PaymentsContainer.Provider>
                </Page>
              }
            />
            <Route
              path="preorder/:bundleId"
              element={
                <Page title="preorder.now" legacyLocaleId="preorder.now">
                  <ThemeContainer.Provider>
                    <PreorderPaymentContainer.Provider>
                      <PreorderPayments />
                    </PreorderPaymentContainer.Provider>
                  </ThemeContainer.Provider>
                </Page>
              }
            />
            <Route
              path="complete_preorder_checkout"
              element={
                <Page
                  title="payment.checkoutComplete.pageTitle"
                  legacyLocaleId="payment.checkoutComplete.pageTitle"
                >
                  <ThemeContainer.Provider>
                    <PreorderCheckoutSuccess />
                  </ThemeContainer.Provider>
                </Page>
              }
            />
            <Route
              path="error_preorder_payment"
              element={
                <Page
                  title="payment.paymentError.pageTitle"
                  legacyLocaleId="payment.paymentError.pageTitle"
                >
                  <ThemeContainer.Provider>
                    <PreorderCheckoutError />
                  </ThemeContainer.Provider>
                </Page>
              }
            />
            <Route
              path="preorder/cancel-preorder/:bundleId/checkout/:checkoutId"
              element={
                <Page
                  title="payment.checkoutCancel.pageTitle"
                  legacyLocaleId="payment.checkoutCancel.pageTitle"
                >
                  <ThemeContainer.Provider>
                    <PreorderCancelOrderFlow />
                  </ThemeContainer.Provider>
                </Page>
              }
            />
            <Route
              path="preorder/cancel-preorder/:bundleId"
              element={
                <Page
                  title="payment.checkoutCancel.pageTitle"
                  legacyLocaleId="payment.checkoutCancel.pageTitle"
                >
                  <ThemeContainer.Provider>
                    <PreorderCancelOrderFlow />
                  </ThemeContainer.Provider>
                </Page>
              }
            />
            <Route
              path="cancel_checkout"
              element={
                <Page
                  title="payment.checkoutCancel.pageTitle"
                  legacyLocaleId="payment.checkoutCancel.pageTitle"
                >
                  <CheckoutContainer.Provider>
                    <LocationCheckoutCancel />
                  </CheckoutContainer.Provider>
                </Page>
              }
            />
            <Route
              path="error_payment"
              element={
                <Page
                  title="payment.paymentError.pageTitle"
                  legacyLocaleId="payment.paymentError.pageTitle"
                >
                  <CheckoutContainer.Provider>
                    <LocationCheckoutError />
                  </CheckoutContainer.Provider>
                </Page>
              }
            />
            <Route
              path="complete_checkout"
              element={
                <Page
                  title="payment.checkoutComplete.pageTitle"
                  analyticsIdentifier="view.payment.flow.result"
                >
                  <CheckoutContainer.Provider>
                    <LocationCheckoutSuccess />
                  </CheckoutContainer.Provider>
                </Page>
              }
            />
            <Route
              path="register_account"
              element={
                <Page
                  title="payment.registerAccount.pageTitle"
                  legacyLocaleId="payment.registerAccount.pageTitle"
                >
                  <PayAppWrapper>
                    <RegisterAccount />
                  </PayAppWrapper>
                </Page>
              }
            />
            <Route
              path="register_account_success"
              element={
                <Page
                  title="registerAccountSuccess.pageTitle"
                  legacyLocaleId="registerAccountSuccess.pageTitle"
                >
                  <PayAppWrapper>
                    <RegisterAccountSuccess />
                  </PayAppWrapper>
                </Page>
              }
            />
            <Route
              path="voucher"
              element={
                <Page
                  title="payment.voucherPage.pageTitle"
                  legacyLocaleId="payment.voucherPage.pageTitle"
                >
                  <VoucherPage />
                </Page>
              }
            />
            <Route
              path={RESERVATIONS_CANCEL_ROUTE}
              element={
                <Page
                  title="payment.reservationsCancel.pageTitle"
                  legacyLocaleId="payment.reservationsCancel.pageTitle"
                >
                  <ReservationsCancel />
                </Page>
              }
            />
          </Route>
          <Route
            path={CLUB_MEMBER_REFERRAL_PAGE_ROUTE}
            element={
              <Page
                title="ClubMemberReferral.PageTitle"
                legacyLocaleId="ClubMemberReferral.PageTitle"
              >
                <ClubMemberReferralPage />
              </Page>
            }
          />
          <Route
            path={CLUB_MEMBER_APPLICATION_PAGE_ROUTE}
            element={
              <Page
                title="ClubMemberReferral.PageTitle"
                legacyLocaleId="ClubMemberReferral.PageTitle"
              >
                <ClubMemberApplicationPage />
              </Page>
            }
          />
          <Route
            path={LUCA_PREORDER_ORDER_ROUTE}
            element={
              <Page title="generic.preorder" legacyLocaleId="generic.preorder">
                <ThemeContainer.Provider>
                  <LucaPreorderOrderFlow />
                </ThemeContainer.Provider>
              </Page>
            }
          />
          <Route
            path={APPLE_SSO_SUCCESS}
            element={
              <Page
                title="AppleSSOSuccess.PageTitle"
                legacyLocaleId="AppleSSOSuccess.PageTitle"
              >
                <AppWrapper>
                  <AppleSSOSuccessPage />
                </AppWrapper>
              </Page>
            }
          />
          <Route
            path={APPLE_SSO_ERROR}
            element={
              <Page
                title="AppleSSOError.PageTitle"
                legacyLocaleId="AppleSSOError.PageTitle"
              >
                <AppWrapper>
                  <AppleSSOErrorPage />
                </AppWrapper>
              </Page>
            }
          />
          <Route
            path={USER_EXISTS_ERROR}
            element={
              <Page
                title="UserExistsError.PageTitle"
                legacyLocaleId="UserExistsError.PageTitle"
              >
                <AppWrapper>
                  <UserExistsErrorPage />
                </AppWrapper>
              </Page>
            }
          />
        </Routes>
      </Suspense>
    </ConfigProvider>
  );
};
