import { createContainer } from 'unstated-next';
import { useParams, useSearchParams } from 'react-router-dom';
import { ApiKeyContainerTypes } from 'types';
import { useGetOrderSubIdForLocation } from 'hooks';

const useApiKeyContainer = (): ApiKeyContainerTypes => {
  const { locationId } = useParams();
  const [searchParameters] = useSearchParams();
  const apiKey = searchParameters.get('apiKey');
  const terminalId = searchParameters.get('terminalId');

  const { data: subscription, isLoading: isSubscriptionLoading } =
    useGetOrderSubIdForLocation({
      locationId,
      apiKey,
    });

  return {
    apiKey,
    terminalId,
    subscriptionId: subscription?.subscriptionId,
    isSubscriptionLoading,
  };
};

export const ApiKeyContainer = createContainer(useApiKeyContainer);
