import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { StayUserContainerTypes } from 'types';
import {
  useGetStayBooking,
  useGetActiveStayReservationsForConsumer,
  useGetReservationFolios,
} from 'hooks';
import { useConsumerContext } from 'context';
import {
  getIsUnknownUserFromLocalStorage,
  getMainFolio,
  getDepositFolio,
  getSelectedReservationReferenceFromLocalStorage,
  setIsUnknownUserFromLocalStorage,
  setSelectedReservationReferenceToLocalStorage,
  removeKeyFromLocalStorage,
} from 'utils';
import {
  STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY,
  STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY,
} from 'constants/localStorage';
import { StayContainer } from './StayContainer';

const useStayUserContainer = (): StayUserContainerTypes => {
  const { discoverId } = useParams();
  const [searchParameters] = useSearchParams();
  const pmsReferenceParameter = searchParameters.get('pmsReference');
  const primaryGuestLastNameParameter = searchParameters.get(
    'primaryGuestLastName'
  );

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [isLoginViaParameter, setIsLoginViaParameter] = useState(false);
  const { consumerData } = useConsumerContext();

  useEffect(() => {
    if (pmsReferenceParameter && primaryGuestLastNameParameter) {
      removeKeyFromLocalStorage(STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY);
      removeKeyFromLocalStorage(STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY);
      setIsLoginViaParameter(true);
    }
  }, [pmsReferenceParameter, primaryGuestLastNameParameter]);

  const isUnknownUser = getIsUnknownUserFromLocalStorage();
  const selectedReservationReference =
    getSelectedReservationReferenceFromLocalStorage();

  const setIsUnknownUser = (value: boolean) => {
    setIsUnknownUserFromLocalStorage(value);
    forceUpdate();
  };

  const { isPMSconnected } = StayContainer.useContainer();

  const setSelectedReservation = (value: string) => {
    setSelectedReservationReferenceToLocalStorage(value);
    forceUpdate();
  };

  const {
    data: activeReservations,
    isLoading: isActiveReservationsLoading,
    refetch: refetchActiveReservations,
    isRefetching: isActiveReservationsRefetching,
  } = useGetActiveStayReservationsForConsumer({
    consumerData,
    discoverId,
    isPMSconnected,
  });

  const {
    data: booking,
    isLoading: isBookingLoading,
    refetch: refetchBooking,
  } = useGetStayBooking({
    pmsReference: selectedReservationReference,
    discoverId,
    consumerData,
    isPMSconnected,
  });

  const {
    data: folioResponse,
    isLoading: isFoliosLoading,
    refetch: refetchFolio,
  } = useGetReservationFolios({
    pmsReference: selectedReservationReference,
    discoverId,
    consumerData,
    isPMSconnected,
  });

  const mainFolio = getMainFolio(folioResponse?.folios);
  const depositFolio = getDepositFolio(folioResponse?.folios);

  const resetLoginViaParameter = () => setIsLoginViaParameter(false);

  return {
    selectedReservationReference,
    activeReservations: activeReservations?.data,
    isUnknownUser: !!isUnknownUser,
    setIsUnknownUser,
    setSelectedReservation,
    isActiveReservationsLoading,
    isBookingLoading,
    isActiveReservationsRefetching,
    isFoliosLoading,
    refetchActiveReservations,
    refetchBooking,
    refetchFolio,
    isLoginViaParameter,
    resetLoginViaParameter,
    booking: booking
      ? {
          ...booking,
          totalAmountPaid: folioResponse?.totalAmountPaid,
          totalOpenAmount: folioResponse?.totalOpenAmount,
          totalChargesPlusProjectedStayAmount:
            folioResponse?.totalChargesPlusProjectedStayAmount,
          folioPaymentType: folioResponse?.folioPaymentType,
          paymentCanBeCaptured: folioResponse?.paymentCanBeCaptured,
          mainFolio,
          depositFolio,
        }
      : null,
  };
};

export const StayUserContainer = createContainer(useStayUserContainer);
