import { SupportedCurrency, OpeningHoursType, OrderingHoursType } from 'types';

export type LocationDescriptionType = {
  localeEn: string | null;
  localeDe: string | null;
  [key: string]: string | null | undefined;
};

export type LocationData = {
  locationId: string;
  name: string;
  publicPhone: string;
  groupName: string;
  groupId: string;
  discoverId: string;
  locationName: string | null | undefined;
  lat: number | null;
  lng: number | null;
  openingHours: OpeningHoursType;
  kitchenClosure: OpeningHoursType;
  deletedAt: number;
  locationType: string | null | undefined;
  address: string;
  discoveryUrl: string;
  discoveryImageUrl: string | undefined;
  description?:
    | {
        localeDe: string | null;
        localeEn: string | null;
      }
    | undefined;
};

export type LocationLinksData = {
  [key: string]: string;
};

export type ExtraLinkType = {
  imageUrl?: string;
  label: string;
  url: string;
  uuid: string;
};

export type PublicLocationResponseType = {
  uuid: string;
  paymentActive: boolean;
  orderingClosedUntil: string | null;
  orderingHours: OrderingHoursType;

  posGroupId: string | null;
  isOpenForOrderingNow: boolean;

  locationId: string;
  name: string;
  locationName: string | null;
  groupName: string;
  groupId: string;
  discoverId: string;
  lat?: number | null;
  lng?: number | null;
  radius?: number;
  averageCheckinTime?: number | null;
  entryPolicy?: string | null;
  publicPhone?: string;
  companyName?: string;
  defaultCurrency: SupportedCurrency;
};

export type ServiceChargeItem = {
  uuid: string;
  name: string;
  price: number;
  description: string;
};

export type PublicLocationSettingsResponseType = {
  orderCancellationDurationFromShowStart: number | null;
  selfCheckoutEnabled: boolean;
  requiresConsumerServiceCharge: boolean;
  serviceChargeItems: ServiceChargeItem[] | null;
};

export enum LocationType {
  RESTAURANT_TYPE = 'restaurant',
  NURSING_HOME_TYPE = 'nursing_home',
  HOTEL_TYPE = 'hotel',
  STORE_TYPE = 'store',
  BASE_TYPE = 'base',
}
