import { textEllipsis } from 'constants/globalStyles';
import styled, { css } from 'styled-components';

export const breakword = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
`;

export const BoldText15 = styled.div`
  font-family: GT-Walsheim-Bold, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
`;

export const NormalText15 = styled.div`
  font-family: GT-Walsheim-Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
`;

export const BoldText15Black = styled(BoldText15)`
  color: black;
`;

export const BoldText15White = styled(BoldText15)`
  color: white;
  ${textEllipsis}
`;

export const BoldText17Black = styled(BoldText15)`
  font-size: 17px;
  color: black;
  line-height: 24px;
`;

export const BoldText17White = styled(BoldText15)`
  font-size: 17px;
  color: white;
  line-height: 24px;
`;

export const NormalText15Black = styled(NormalText15)`
  color: black;
`;

export const NormalText15White = styled(NormalText15)`
  color: white;
`;

export const NormalText17Black = styled(NormalText15)`
  font-size: 17px;
  color: black;
  line-height: 24px;
`;

export const NormalText17White = styled(NormalText15)`
  font-size: 17px;
  color: white;
  line-height: 24px;
`;

export const HeadlineWhite = styled(BoldText15)`
  color: white;
  font-size: 21px;
  line-height: 28px;
`;
